import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { HorizontalBanner, TripleCards, BigBanner, PageHero, DoubleCards } from "@components/index";
import { ButtonComponent as Button } from "@components/Button";
import { useAddOns } from "hooks/nclexreview/useAddOns";
import { SeoTags } from "@components/index";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";

export function AddOns() {
    // CommonJS
    const data = useAddOns();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_ADDONS_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }
    
    return (
        
        <Container className="p-0 overflow-hidden page-pre-ngn-prep" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside} extraWaveClass="wave-fixed"/>
            <Button containerClass="center" color={data.cta.buttonGradient} url={data.cta.cta_url} label={data.cta.button} />
            <TripleCards waveType="" data={data.tripleCardsTextOutside1} />
            <Container className="qbank-buttons center">
                <Button containerClass="" color={data.cta2.buttonGradient} url={data.cta2.cta_url} label={data.cta2.button} />
                <Button containerClass="" color={data.cta3.buttonGradient} url={data.cta3.cta_url} label={data.cta3.button} />
            </Container>
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside2} extraWaveClass="add-ons-wave-big wave-fixed"/>
            <HorizontalBanner
                waveType="nclex"
                buttonColClass="nclex-horizontal-button-col d-flex my-3"
                rowClass="nclex-horizontal-banner-row"
                data={data.banner}
            />
            <BigBanner
                waveType="nclex"
                extradata={data.rowPillarLeft}
                imgContainerClass="nclex-bigbanner-img-container"
                reverse
                data={data.heroBadge}
            />
            <DoubleCards type="carousel" data={data.tripleCards} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric" data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
