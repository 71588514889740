import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { BulletedCard, SimpleCard, RowListCard } from "components/Card";
import { useBreakPoint } from "hooks/useBreakPoint";
import { ButtonComponent as Button } from "@components/Button";
import { get } from "lodash";

export const RowPillar = ({ data, reverse = false, type = 'quote' }) => {
    const { break768 } = useBreakPoint();
    
    const bodyText = get(data, "cardComponent.fields.bodyText", "");
    const imgSrc = get(data, "cardComponent.fields.image.fields.file.url", "");
    const altImg = get(data, "cardComponent.fields.image.fields.description", "Row Pillar Image");

    return (
        <Row className={`d-flex ${break768 ? "flex-column-reverse" : reverse ? "flex-row-reverse" : ""}`}>
            <Col sm={10} md={6} className={`justify-content-center d-flex align-items-center`} 
            style={{right: break768 ? null : reverse ? null : "20%", 
                    left: break768 ? null : reverse ? "20%" : "0%"}} >
                <Image className={`row-pillar-image-col ${reverse ? "row-pillar-image-col-reverse": ""} row-pillar-image-col  w-100`} style={{zIndex: '4'}} src={imgSrc} alt={altImg} />
            </Col>
            <Col lg={5} md={6} className="p-5 row-pillar-col mr-md-5">
                {type !== 'rowList' &&
                    <h2 className="row-pillar-title my-md-5">{data.header}</h2>
                }
                {type === 'rowList' &&
                    <h2 className="row-pillar-title my-md-5">Upcoming<br/><span style={{'white-space': 'nowrap'}}>lunch and learns</span></h2>
                }
                {type === 'quote' && 
                    <SimpleCard
                        cardClass="row-pillar-card"
                        cardBodyClass="justify-content-start align-items-start"
                        content={bodyText}
                        title={''}
                        cardTextTitleClass="text-left-custom row-pillar-card-content"
                        titleClass="text-left-custom row-pillar-card-title"
                    />
                }
                {type === 'bulleted' &&
                    <BulletedCard
                        cardClass="row-pillar-card"
                        cardBodyClass="justify-content-start align-items-start"
                        content={bodyText.split(/\r?\n/)}
                        title={''}
                        cardTextTitleClass="text-left-custom row-pillar-card-content"
                        titleClass="text-left-custom row-pillar-card-title"
                    />
                }
                {type === 'rowList' &&
                    <RowListCard
                        cardClass="row-pillar-card"
                        cardBodyClass="justify-content-start align-items-start"
                        content={bodyText.split(/\r?\n/)}
                        title={''}
                        cardTextTitleClass="text-left-custom row-pillar-card-content"
                        titleClass="text-left-custom row-pillar-card-title"
                    />
                }
                <br/>
                {data.button !== undefined ? <Button color={data.button.color} url={data.button.url} label={data.button.title} /> : ''}
            </Col>
        </Row>
    );
};
