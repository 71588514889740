import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHeader } from "@hooks/useHeader";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { getImagesResponsive } from "@styles/images";
import { ButtonComponent as Button } from "@components/Button";
import { useEffect } from "react";

export function HeaderComponent() {
    const header = useHeader();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const headerBreak = useMediaQuery({
        query: "(max-width: 1300px)",
    });

    const [visible, setVisible] = useState(-1);
    const [showNav, setShowNav] = useState(false);

    const headerLogo = getImagesResponsive(isDesktopOrLaptop).logoHeader;

    const handleDropdownClick = (e, item, index) => {
        if (!item.fields.pillarNavigation) {
            window.location.href = item.fields.url;
            return;
        }

        if (!isDesktopOrLaptop) {
            if (visible === index) {
                window.location.href = item.fields.url;
            }
        } else {
            // e.preventDefault();
            window.location.href = item.fields.url;
        }
    };

    const handleKeypress = (e, item, index) => {
        if (e.code === "Enter") {
            window.location.href = item.fields.url;
        }
    };

    const onMouseEnter = (e, index) => {
        setVisible(index);
    };
    const onMouseLeave = () => {
        setVisible(-1);
    };
    const onFocus = (e, index) => {
        setVisible(index);
    };

    const navToggle = () => {
        setShowNav(!showNav);
    };

    useEffect(() => {
        if (headerBreak) {
            setShowNav(!headerBreak);
        }
    }, [headerBreak]);

    const renderSignIn = (forceRender = false) => {
        if (forceRender && !headerBreak) {
            return <Button containerClass="sign-in-button" label="Sign In" url="/login" />;
        }
        if (headerBreak && !forceRender) {
            return <Button containerClass="sign-in-button" label="Sign In" url="/login" />;
        }
        return null;
    };
    if (header) {
        return (
            <header>
                <Navbar className="header-container" bg="light" expand="lg">
                    <Container className="justify-content-center navbar-container">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex justify-content-center align-content-center flex-column">
                                <a className="header-logo-anchor" href="/">
                                    <Image className="logo-img" src={headerLogo} alt="Header Logo"/>
                                </a>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                                {renderSignIn()}
                                <Navbar.Toggle
                                    className="header-toggle"
                                    onClick={navToggle}
                                    aria-controls="basic-navbar-nav"
                                />
                            </div>
                        </div>
                        <div
                            className={`header-nav ${headerBreak ? "d-none" : "d-block"} ${showNav ? "show" : ""}`}
                            id="basic-navbar-nav">
                            <div className="me-auto navbar-nav p-1">
                                {header.map((item, index) => {
                                    return (
                                        <div key={item.fields.linkText}>                                    
                                            <a
                                                href={`${headerBreak ? "#" : item.fields.url} `}
                                                onMouseLeave={onMouseLeave}
                                                onMouseEnter={(e) => onMouseEnter(e, index)}
                                                title={item.fields.linkText}
                                                className={`nav-item ${
                                                    !item.fields.pillarNavigation ? "nav-no-item" : ""
                                                } dropdown`}
                                                id={"basic-nav-dropdown_"+index}>
                                                <div className="d-flex flex-row align-items-center position-relative">
                                                    <div
                                                        tabIndex={"0"}
                                                        onClick={(e) => handleDropdownClick(e, item, index)}
                                                        onFocus={(e) => onFocus(e, index)}
                                                        onKeyDown={(e) => handleKeypress(e, item, index)}>
                                                        {item.fields.linkText}
                                                    </div>
                                                    {item.fields.pillarNavigation && (
                                                        <div className="header-arrow">
                                                            <Image
                                                                className="d-block w-100"
                                                                src={require("../../assets/mock/arrow_right_2.png")}
                                                                alt="Arrow"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                    <div className={`nav-dropdown ${visible === index ? "visible" : "hidden" }`}>
                                                        {item.fields.pillarNavigation &&
                                                            item.fields.pillarNavigation.map((pillar) => {
                                                                return (
                                                                    <a
                                                                        href={pillar.fields.url}
                                                                        className="nav-dropdown-item"
                                                                        key={pillar.fields.linkText}>
                                                                        <div href={pillar.fields.url}>
                                                                            {pillar.fields.linkText}
                                                                        </div>
                                                                    </a>
                                                                );
                                                            })}
                                                    </div>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Container>
                    {renderSignIn(true)}
                </Navbar>
            </header>
        );
    }
    return null;
}
