import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "components";
import { useSocial } from "hooks/community/useSocial";
import { ImagesGallery } from "@components/ImagesGallery";
import { SocialMediaBanner } from "components/SocialMediaBanner";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { SeoTags } from "@components/index";


export function Social() {
    // CommonJS
    const data = useSocial();
    const seoData = useSEO(contentFulIds.REACT_APP_SOCIAL_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor='orange'/>
            <div className="nclex-wave-1 community-wave-1" style={{height: "175%"}}></div>
            <SocialMediaBanner data={data.socialMediaBanner} />
            <ImagesGallery data={data.imagesGallery9Items}/>
            <div className="nclex-wave-2 community-wave-2" style={{height: "100%"}}></div>
        </Container>
    );
}
