import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { ButtonComponent as Button } from "@components/Button";
import { Card } from "react-bootstrap";
import { get } from "lodash";

export const BigBanner = ({ data, extradata, reverse, rowClass, imgContainerClass, contentColClass, waveType }) => {
    if (!data) {
        return null;
    }
    const cta = data.cta && data.cta[0].fields;
    const heroBadgeCard = data.heroBadgeCard && data.heroBadgeCard.fields;
    const rowCta = get(extradata, "cardComponent.fields.cta.fields");

    return (
        <>
            {waveType && <div className="big_banner_wave"></div>}

            <Row
                className={`big-banner-row d-md-flex ${
                    reverse ? "flex-md-row-reverse" : "flex-md-row"
                } justify-content-center align-items-center m-4 ${rowClass}`}>
                <Col md={6} sm={12}>
                    <div
                        className={`big-banner-img-container d-flex justify-content-center align-items-center ${imgContainerClass}`}>
                        <Image
                            className="d-block w-100"
                            src={
                                reverse
                                    ? require("../assets/mock/nclex_orange_shape.png")
                                    : data.backgroundImage.fields.file.url
                            }
                            alt="NCLEX Orange Shape"
                        />
                    </div>
                </Col>

                {!reverse && (
                    <Col md={6} sm={12}>
                        <div className="mb-5" style={{display: 'none'}}>
                            <Image
                                className="d-block big-banner-img-hr"
                                src={require("@assets/mock/bigbanner-hr.png")}
                                alt="Big Banner HR"
                            />
                        </div>
                        <div className="big-banner-container w-75">
                            <h2>{data.header}</h2>
                            <p className="big-banner-p">{data.bodyText}</p>
                        </div>
                        <Button label={cta.button} url={cta.cta_url} />
                    </Col>
                )}
                {reverse && (
                    <Col className={contentColClass} md={6} sm={12}>
                        <Card className="box-shadow card-radius">
                            <Card.Body className="d-flex flex-column">
                                <div className="big-banner-container w-75">
                                    <h2>{data.header}</h2>
                                    <p className="big-banner-p">{heroBadgeCard.bodyText}</p>
                                </div>
                                <div className="mb-5">
                                    <Image className="d-block w-100" src={require("@assets/mock/guaranteed.png")} alt="Guaranteed"/>
                                </div>
                            </Card.Body>
                        </Card>
                        <Button label={rowCta.button} url={rowCta.cta_url} />
                    </Col>
                )}
            </Row>
        </>
    );
};
