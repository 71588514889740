import React from "react";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";
import { useBreakPoint } from "@hooks/useBreakPoint";
import { HeroContent } from "@components/HeroContent";


export const Hero = ({ data }) => {
    const { isMobile } = useBreakPoint();
    if(!data) {
        return null
    }

    const cards = data.cardComponent.map((item) => {
        
        return {
            ...item.fields,
            cta: item.fields.cta.fields,
            secondaryCta: item.fields.secondaryCta?.fields,
            image: item.fields.image.fields.file.url,
            alt: item.fields.image.fields.description

        }
    });

    let timeToRotate = 4000;

    if(data.timeToRotate !== undefined) {
        timeToRotate = data.timeToRotate+"000"
    }


    return (
        <Row className="hero-row">
            <Carousel interval={timeToRotate} indicators={cards?.length > 1} controls={cards?.length > 1}>
                {cards &&
                    cards.map((item, index) => {
                        return (
                            <Carousel.Item key={item.header}>
                                <Carousel.Caption>
                                    <HeroContent
                                        title={item.header}
                                        bodyText={item.bodyText}
                                        url={item.cta.cta_url}
                                        url2={item.secondaryCta?.cta_url}
                                        label={item.cta.button}
                                        label2={item.secondaryCta?.button}
                                        id1={item.cta.buttonId}
                                        id2={item.secondaryCta?.buttonId}
                                    />
                                </Carousel.Caption>
                                <Image
                                    className="d-block w-100"
                                    src={isMobile ? data.slidesMobile[index].fields.file.url : item.image}
                                    alt={item.alt}
                                />
                            </Carousel.Item>
                        );
                    })}
            </Carousel>
            <Image className="home-wave-new" src={isMobile ? require("@assets/updatedWaves/mobile/home_wave.png") : require("@assets/updatedWaves/desktop/home_wave.png")}/>
        </Row>
    );
};
