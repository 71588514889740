import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ButtonComponent as Button } from "@components/Button";

export const HorizontalBanner = ({ data, rowClass, buttonColClass }) => {
    if (!data) {
        return null;
    }

    const cta = data.cta;
    return (
        <Row
            className={`horizontal-banner-row d-md-flex flex-md-row justify-content-center align-items-center ${rowClass}`}>
            <Col className="text-center" md={8}>
                <h1>{data.header}</h1>
            </Col>
            <Col className={`w-auto ${buttonColClass}`} md={4}>
                {cta &&
                    cta.map(({ fields }) => {
                        return (
                            <Button key={fields.cta_url} containerClass="horizontal-banner-button" label={fields.button} url={fields.cta_url} />
                        );
                    })}
            </Col>
        </Row>
    );
};
