import "./style.css";
import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "components";
import { BulletedCard, UlListCard } from "components/Card";
import { useGuarantee } from "hooks/about/useGuarantee";
import { ButtonComponent as Button } from "@components/Button";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { SeoTags } from "@components/index";

export function Guarantee() {
    // CommonJS
    const data = useGuarantee();
    const seoData = useSEO(contentFulIds.REACT_APP_ABOUT_GUARANTEE_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    const waveClasses = {
        home: "home-wave-2",
        nclex: `nclex-wave-1`
    }

    return (
        <Container className="p-0 overflow-hidden page-guarantee" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <div className={waveClasses["nclex"] + " about-guarantee-wave"} ></div>
            <Row className={`nclex-triple-cards-row triple-cards-row d-md-flex flex-md-row justify-content-center align-items-center m-2`}>
                <Row className="d-flex justify-content-start align-items-center adjustment-guarantee">
                    <h1>{data.banner.header}</h1>
                </Row>
                <BulletedCard        
                    cardBodyClass="justify-content-start align-items-start row-pillar-card"
                    content={data.banner.bodyText.split(/\r?\n/)}
                    title=''
                    cardTextTitleClass="text-left-custom row-pillar-card-content"
                    titleClass="text-left-custom row-pillar-card-title"
                />
                <div className="top-spacer"/>
                <div className="top-spacer"/>
                <Row className="d-flex justify-content-start align-items-center adjustment-guarantee inherit-zindex-2">
                    <center><span className="text-italic text-bold">{data.bodyText.bodyTextLongText}</span></center>
                </Row>
                
                <UlListCard        
                    cardBodyClass="justify-content-start align-items-start row-pillar-card inherit-zindex-2"
                    content={data.banner1.bodyText.split(/\r?\n/)}
                    title={data.banner1.header}
                    cardTextTitleClass="text-left-custom row-pillar-card-content"
                    titleClass="text-left-custom row-pillar-card-title"
                />

                <div className="top-spacer"/>
                <div className="top-spacer"/>
                <Row className="d-flex justify-content-start align-items-center adjustment-guarantee inherit-zindex-2">
                    <center><span className="text-italic text-bold">{data.bodyText2.bodyTextLongText}</span></center>
                </Row>

                <UlListCard        
                    cardBodyClass="justify-content-start align-items-start row-pillar-card inherit-zindex-2"
                    content={data.banner3.bodyText.split(/\r?\n/)}
                    title={data.banner3.header}
                    cardTextTitleClass="text-left-custom row-pillar-card-content"
                    titleClass="text-left-custom row-pillar-card-title"
                />

                <div className="top-spacer"/>
                <div className="top-spacer"/>
                <Row className="d-flex justify-content-start align-items-center adjustment-guarantee inherit-zindex-2">
                    <center><span className="text-italic text-bold">{data.bodyText4.bodyTextLongText}</span></center>
                </Row>
                <center>
                    <Button color={data.cta.buttonGradient} url={data.cta.cta_url} label={data.cta.button} />
                </center>
                <div className="top-spacer"/>
                <div className="top-spacer"/>
                <Row className="d-flex justify-content-start align-items-center adjustment-guarantee inherit-zindex-2">
                    <center><span className="text-italic-minor text-bold">{data.bodyText5.bodyTextLongText}</span></center>

                </Row>
                <div className="nclex-wave-2 wave-guarantee"></div>
                
            </Row>
            
        </Container>
    );
}
