import { useEffect, useState } from "react";
import { getDataById } from "@services/contentfulClient";

export const useSEO = (contentfulId = '') => {
    const [data, setData] = useState();
    useEffect(() => {
        (async () => {
            const data = await getDataById(contentfulId);
            setData(data['items'][0]);
        })();
    }, [contentfulId]);
    return data;
};
