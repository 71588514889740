import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CardsWithOverlayImage } from "components/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { get } from "lodash";
import { useBreakPoint } from "hooks/useBreakPoint";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

export const DoubleCards = ({ data, type, waveType, extraWaveClass, rowClass, isAmbassadors }) => {
    const { break768 } = useBreakPoint();
    const [currentSlide, setCurrentSlide] = useState(break768 ? 0 : 1);

    if (!data) {
        return null;
    }

    const waveClasses = {
        home: `home-wave-1 ${extraWaveClass}`,
        nclex: `nclex-wave-2 ${extraWaveClass}`,
    };

    const beforeChangeAmbassadors = (nextSlide, state) => {
        const list = { 
            0: 1,
            1: 2,
            2: 3,
            3: 0,
            4: 1,
            5: 2,
            6: 3,
            7: 0,
            8: 1
        };

        const listMobile = { 
            0: 2,
            1: 3,
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 0,
            7: 1,
            8: 2
        };

        if (state.deviceType === 'mobile') {
            setCurrentSlide(listMobile[nextSlide]);
        }
        else {
            setCurrentSlide(list[nextSlide]);
        }
    }

    const beforeChange = (nextSlide, state) => {
        const list = { 
            0: 1,
            1: 2, 
            2: 0, 
            3: 1, 
            4: 2, 
            5: 0, 
            6: 1
        };
        
        setCurrentSlide(list[nextSlide]);
    };
    const renderAsymetric = () => {
        if (type === "asymetric") {
            const cardMap = Object.values(data);
            const result = cardMap
                .filter((item) => typeof item !== "string" && !item.fields.ctaName)
                .map((item) => {
                    return { ...item.fields, image: item?.fields?.image?.fields?.file };
                });
            return (
                <>
                    <h1>{data.header}</h1>
                    <Carousel
                        beforeChange={beforeChange}
                        partialVisible={true}
                        infinite={true}
                        swipeable={true}
                        responsive={responsive}>
                        {result &&
                            result.map((rest, index) => {
                                const cta = rest.cta[0];
                                return (
                                    <CardsWithOverlayImage
                                        active={currentSlide === index}
                                        type="asymetric"
                                        cardClass="m-3"
                                        classes={{
                                            cardBody: "nclex-blog-card-body justify-content-center",
                                            contentContainer: "nclex-blog-card-container",
                                            bodyText: "nclex-blog-card-body-text",
                                            title: "nclex-blog-card-title",
                                        }}
                                        title={get(rest, "header", "")}
                                        // bodyText={get(rest, "bodyText", "")}
                                        label={get(cta, "fields.button", "")}
                                        url={get(cta, "fields.cta_url", "")}
                                        imgSrc={get(rest, "image.url", "")}
                                        key={index}
                                    />
                                );
                            })}
                    </Carousel>
                </>
            );
        }
        return null;
    };
    const renderCarousel = () => {
        if (type === "carousel") {
            const cardMap = Object.values(data);
            const result = cardMap
                .filter((item) => typeof item !== "string" && !item.fields.ctaName)
                .map((item) => {
                    return { ...item.fields, image: item?.fields?.image?.fields?.file };
                });
            return (
                <>
                    <h1>{data.header}</h1>
                    <Carousel
                        beforeChange={beforeChange}
                        partialVisible={true}
                        infinite={true}
                        swipeable={true}
                        responsive={responsive}>
                        {result &&
                            result.map((rest, index) => {
                                return (
                                    <CardsWithOverlayImage
                                        active={currentSlide === index}
                                        type={"carousel"}
                                        cardClass="m-3 "
                                        title={get(rest, "header", "")}
                                        bodyText={get(rest, "bodyText", "")}
                                        label={get(rest, "cta.fields.button", "")}
                                        url={get(rest, "cta.fields.cta_url", "")}
                                        imgSrc={get(rest, "image.url", "")}
                                        classes={{cardBody: "justify-content-center"}}
                                        key={index}
                                    />
                                );
                            })}
                    </Carousel>
                </>
            );
        }
        return null;
    };

    const renderAmbassadors = () => {
        if (type === "ambassadors") {
            const cardMap = Object.values(data);
            const result = cardMap
                .filter((item) => typeof item !== "string" && !item.fields.ctaName)
                .map((item) => {
                    return { ...item.fields, image: item?.fields?.image?.fields?.file };
                });
            return (
                <>
                    <h1>{data.header}</h1>
                    <Carousel
                        beforeChange={beforeChangeAmbassadors}
                        partialVisible={true}
                        infinite={true}
                        swipeable={true}
                        responsive={responsive}>
                        {result &&
                            result.map((rest, index) => {
                                return (
                                    <CardsWithOverlayImage
                                        active={currentSlide === index}
                                        type={"ambassadors"}
                                        cardClass="m-3 "
                                        title={get(rest, "header", "")}
                                        bodyText={get(rest, "bodyText", "")}
                                        label={get(rest, "cta.fields.button", "")}
                                        url={get(rest, "cta.fields.cta_url", "")}
                                        imgSrc={get(rest, "image.url", "")}
                                        classes={{cardBody: "justify-content-center"}}
                                        key={index}
                                    />
                                );
                            })}
                    </Carousel>
                </>
            );
        }
        return null;
    }

    const renderContent = () => {
        if (type !== "carousel" && type !== "asymetric" && type !== "ambassadors") {
            const cardComponent1 = {
                ...data?.cardComponent?.fields,
                cta: data.cardComponent?.fields?.cta?.fields,
                image: data?.cardComponent?.fields?.image?.fields?.file,
            };
            const cardComponent2 = {
                ...data?.cardComponent2?.fields,
                cta: data?.cardComponent2?.fields?.cta?.fields,
                image: data?.cardComponent2?.fields?.image?.fields?.file,
            };
            return (
                <>
                    <h1>{data.header}</h1>
                    <Col className={`d-md-flex flex-md-row justify-content-center mx-md-5 mx-sm-3 `}>
                        <CardsWithOverlayImage
                            cardClass="m-3 double-card"
                            title={get(cardComponent1, "header")}
                            label={get(cardComponent1, "cta.button", "")}
                            url={get(cardComponent1, "cta.cta_url")}
                            imgSrc={get(cardComponent1, "image.url")}
                            classes={{cardBody: "justify-content-center"}}
                        />
                        <CardsWithOverlayImage
                            cardClass="m-3 double-card"
                            title={get(cardComponent2, "header")}
                            label={get(cardComponent2, "cta.button", "")}
                            url={get(cardComponent2, "cta.cta_url")}
                            imgSrc={get(cardComponent2, "image.url")}
                            classes={{cardBody: "justify-content-center"}}
                        />
                    </Col>
                </>
            );
        }
        return null;
    };

    return (
        <>
            {waveType && <div className={waveClasses[waveType]}></div>}

            <Row className={`double-card-row overflow-hidden ${rowClass}`}>
                {renderCarousel()}
                {renderAsymetric()}
                {renderContent()}
                {renderAmbassadors()}
            </Row>
        </>
    );
};
