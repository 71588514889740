export const defaultTheme = {
    maxwidth: '1300px',

    white: '#FFFFFF',

    black200: '#AAAFB0',
    black500: '#707070',
    
    blue200: '#E4EAF6',
    blue400: '#BDE4EA',
    blue600: '#59BCCB',
    
    purple200: '#CDD8EF',
    purple400: '#D0BEFF',
    purple500: '#9878EB',

    orange400: '#FF8204',
    orange500: '#E85C0F',
}