import React, { useEffect, useState } from "react";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import Container from "react-bootstrap/Container";
import { ContactOverride } from "./styles";
import pageView from "@components/GTM";
import Select, { components } from 'react-select'
import { useContact } from "hooks/schools/useContact";
import clockIcon from "@assets/contactImages/clockIcon.png";
import emailIcon from "@assets/contactImages/emailIcon.png";
import localIcon from "@assets/contactImages/localIcon.png";
import phoneIcon from "@assets/contactImages/phoneIcon.png";
import calendarIcon from "@assets/contactImages/calendarIcon.png";
import SelectArrow from "@assets/contactImages/SelectArrow.png";
import {
    PageHero,
    SeoTags,
} from "@components/index";

export function SchoolsContact() {
    // CommonJS
    const data = useContact();
    const [apiData, setApiData] = useState({});
    const seoData = useSEO(contentFulIds.REACT_APP_SCHOOLS_CONTACT_PAGE_SEO_ID);
    // set value for default selection
    const [selectedValue, setSelectedValue] = useState("None");

    useEffect(() => {
        pageView(window.location.href);
    }, []);

    useEffect(() => {
        (async () => {
            let apiDataParsed = []
            let responseApiJson = []
            let apiData = await fetch(contentFulIds.REACT_APP_CONTACT_API_URL)
            responseApiJson = await apiData.json();
            for (let i = 0; i<responseApiJson.length; i++){
                const usTerritory = []
                const caTerritory = []
                for (let j = 0; j<responseApiJson[i]['us_territory'].length; j++){
                    usTerritory.push(responseApiJson[i]?.us_territory[j]?.abbreviation);
                    caTerritory.push(responseApiJson[i]?.ca_territory[j]?.abbreviation);
                }

                apiDataParsed.push(
                    {
                        calendly_url: responseApiJson[i]['calendly_url'],
                        curriculum_contact: responseApiJson[i]['curriculum_contact'],
                        email: responseApiJson[i]['email'],
                        name: responseApiJson[i]['name'],
                        phone: responseApiJson[i]['phone'],
                        photo_url: responseApiJson[i]['photo_url'],
                        sales_contact: responseApiJson[i]['sales_contact'],
                        title: responseApiJson[i]['title'],
                        us_territory: usTerritory,
                        ca_territory: caTerritory
                    }
                );
            }

            setApiData(apiDataParsed)

        })()     
    }, []);
    if (!data || !seoData) {
        return null;
    }


    const waveClasses = {
        home: "home-wave-2",
        nclex: `nclex-wave-1`
    }

    const stateOptions = [
        { 
            label: "US States",
            options: [
                { value: "AL", label: "Alabama" },
                { value: "AK", label: "Alaska" },
                { value: "AZ", label: "Arizona" },
                { value: "AR", label: "Arkansas" },
                { value: "CA", label: "California" },
                { value: "CO", label: "Colorado" },
                { value: "CT", label: "Connecticut" },
                { value: "DE", label: "Delaware" },
                { value: "DC", label: "District Of Columbia" },
                { value: "FL", label: "Florida" },
                { value: "GA", label: "Georgia" },
                { value: "HI", label: "Hawaii" },
                { value: "ID", label: "Idaho" },
                { value: "IL", label: "Illinois" },
                { value: "IN", label: "Indiana" },
                { value: "IA", label: "Iowa" },
                { value: "KS", label: "Kansas" },
                { value: "KY", label: "Kentucky" },
                { value: "LA", label: "Louisiana" },
                { value: "ME", label: "Maine" },
                { value: "MD", label: "Maryland" },
                { value: "MA", label: "Massachusetts" },
                { value: "MI", label: "Michigan" },
                { value: "MN", label: "Minnesota" },
                { value: "MS", label: "Mississippi" },
                { value: "MO", label: "Missouri" },
                { value: "MT", label: "Montana" },
                { value: "NE", label: "Nebraska" },
                { value: "NV", label: "Nevada" },
                { value: "NH", label: "New Hampshire" },
                { value: "NJ", label: "New Jersey" },
                { value: "NM", label: "New Mexico" },
                { value: "NY", label: "New York" },
                { value: "NC", label: "North Carolina" },
                { value: "ND", label: "North Dakota" },
                { value: "OH", label: "Ohio" },
                { value: "OK", label: "Oklahoma" },
                { value: "OR", label: "Oregon" },
                { value: "PA", label: "Pennsylvania" },
                { value: "RI", label: "Rhode Island" },
                { value: "SC", label: "South Carolina" },
                { value: "SD", label: "South Dakota" },
                { value: "TN", label: "Tennessee" },
                { value: "TX", label: "Texas" },
                { value: "UT", label: "Utah" },
                { value: "VT", label: "Vermont" },
                { value: "VA", label: "Virginia" },
                { value: "WA", label: "Washington" },
                { value: "WV", label: "West Virginia" },
                { value: "WI", label: "Wisconsin" },
                { value: "WY", label: "Wyoming" }
            ]
        },
        {
            label: "Canadian Provinces",
            options: [
                { value: "AB", label: "Alberta" },
                { value: "BC", label: "British Columbia" },
                { value: "MB", label: "Manitoba" },
                { value: "NB", label: "New Brunswick" },
                { value: "NL", label: "Newfoundland and Labrador" },
                { value: "NT", label: "Northwest Territories" },
                { value: "NS", label: "Nova Scotia" },
                { value: "NU", label: "Nunavut" },
                { value: "ON", label: "Ontario" },
                { value: "PE", label: "Prince Edward Island" },
                { value: "QC", label: "Quebec" },
                { value: "SK", label: "Saskatchewan" },
                { value: "YT", label: "Yukon" }
            ]
        }
    ];
    
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#FFFFFF",
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "grey" : "darkgrey",
          borderRadius: 20,
          minHeight: '65px',
          height: '65px',
          paddingRight: '15px',
          paddingLeft: '25px',
          paddingTop: '3px',
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "grey" : "darkgrey"
          }
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "grey"
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#EA600E" : "white",
            '&:hover': { backgroundColor:  'orange'  }
        }),
        
    };
    
    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <img src={SelectArrow} alt="Select Arrow"></img>
            </components.DropdownIndicator>
          )
        );
      };
    
    function removeFirstComma(str, searchstr)       {
        var index = str.indexOf(searchstr);
        if (index === -1) {
            return str;
        }
        return str.slice(0, index) + str.slice(index + searchstr.length);
    }

    
    
    // handle onChange event of the dropdown
    const handleChange = e => {
        setSelectedValue(e.value);
    }

    //let contactFiltered = apiData[2];
    var contactFiltered;
    for (let i=0; i<apiData.length; i++){
        if(apiData[i]['us_territory'].includes(selectedValue)){
            contactFiltered = apiData[i]
        }else if(apiData[i]['ca_territory']?.includes(selectedValue)){
            contactFiltered = apiData[i]
        }
    }
    
    if(contactFiltered === undefined){
        return (
            <Container className="p-0 overflow-hidden page-schools-subpage page-contact" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor="orange"/>
            <div className={waveClasses["nclex"] + " contact-wave"} ></div>
                <ContactOverride>
                    <div id="contacts-container">
                        <div id="address-box">
                            <div id="address-box-image">
                                <img src={localIcon} alt="Local Icon"></img>
                            </div>
                            <div id="address-box-text">
                                127 South Railroad Ave<br/>Brookhaven, MS<br/>39601
                            </div>
                        </div>
                        <div id="phone-box">
                            <div id="phone-box-image">
                                <img src={phoneIcon} alt="Phone Icon"></img>
                            </div>
                            <div id="phone-box-text">
                                601.833.1961
                            </div>
                        </div>
                        <div id="schedule-box">
                            <div id="schedule-box-image">
                                <img src={clockIcon} alt="Clock Icon"></img>
                            </div>
                            <div id="schedule-box-text">
                                Monday - Friday<br/>8 AM - 5 PM US CST
                            </div>
                        </div>
                    </div>
                    <div className="imageContainerContact">
                        <div className="imageContact">
                            <img src="https://images.ctfassets.net/hk94475o187a/3QXnntkRHWMk6Bf576CbQ1/f775529d8b2bcc6361446d8b1aad75fc/Hurst-Logo-Orange.6963d3b7e87db91dd062.png" width="400" height="400" alt="A person, representative"></img>
                        </div>
                        <div className="select_wrapper">
                            &nbsp;&nbsp;&nbsp;Location *
                            <Select
                                maxMenuHeight={250}
                                placeholder={''}
                                value={stateOptions.find(obj => obj.value === selectedValue)}
                                styles={customStyles}
                                options={stateOptions}
                                onChange={handleChange}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator
                                }}/>
                        </div>
                        
                    </div>
                </ContactOverride>
        </Container>
        )
    }

    return (
        <Container className="p-0 overflow-hidden page-schools-subpage page-contact" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor="orange"/>
            <div className={waveClasses["nclex"]} ></div>
                <ContactOverride>
                    <div id="contacts-container">
                        <div id="address-box">
                            <div id="address-box-image">
                                <img src={localIcon} alt="Local Icon"></img>
                            </div>
                            <div id="address-box-text">
                                127 South Railroad Ave<br/>Brookhaven, MS<br/>39601
                            </div>
                        </div>
                        <div id="phone-box">
                            <div id="phone-box-image">
                                <img src={phoneIcon} alt="Phone Icon"></img>
                            </div>
                            <div id="phone-box-text">
                                601.833.1961
                            </div>
                        </div>
                        <div id="schedule-box">
                            <div id="schedule-box-image">
                                <img src={clockIcon} alt="Clock Icon"></img>
                            </div>
                            <div id="schedule-box-text">
                                Monday - Friday<br/>8 AM - 5 PM US CST
                            </div>
                        </div>
                    </div>
                    <div className="imageContainerContact">
                        <div className="imageContact">
                            <img src={contactFiltered.photo_url !== undefined ? contactFiltered.photo_url  : ''} width="400" height="400" alt="A person, representative"></img>
                        </div>
                        <div className="select_wrapper">
                            &nbsp;&nbsp;&nbsp;Location *
                            <Select
                                maxMenuHeight={250}
                                placeholder={''}
                                value={stateOptions.find(obj => obj.value === selectedValue)}
                                styles={customStyles}
                                options={stateOptions}
                                onChange={handleChange}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator
                                }}/>
                        </div>
                        
                    </div>
                    <div className="contactDataContainer">
                        <div className="below">
                            <div className="contact_info_name">
                                {contactFiltered.title} / {selectedValue}<br/>
                                <span className="rep_name_font">{contactFiltered.name}</span>
                            </div>
                        </div>
                        <div className="below">
                        </div>
                    </div>
                    <div className="contactIconsContainer">
                        <div className="below">
                        <div className="contact_info_phone_wrapper">
                        <div className="contact-phone-image">
                                <img src={phoneIcon} alt="Phone Icon"></img>
                            </div>
                            <div className="contact-phone-text">
                                {contactFiltered.phone}
                            </div>
                    </div>
                        </div>
                        <div className="below">
                        </div>
                    </div>

                    <div className="contactIconsContainer">
                        <div className="below">
                        <div className="contact_info_email_wrapper">
                        <div className="contact-email-image">
                                <img src={emailIcon} alt="Email Icon"></img>
                            </div>
                            <div className="contact-email-text">
                                {contactFiltered.email}
                            </div>
                    </div>
                        </div>
                        <div className="below">
                        </div>
                    </div>
                    <div className="contactIconsContainer">
                        <div className="below">
                        <div className="contact_info_calendar_wrapper">
                        <div className="contact-calendar-image">
                                <img src={calendarIcon} alt="Calendar Icon"></img>
                            </div>
                            <div className="contact-calendar-text">
                                <a href={contactFiltered.calendly_url}>Calendar Link</a>
                            </div>
                    </div>
                        </div>
                        <div className="below">
                        </div>
                    </div>
                </ContactOverride>
        </Container>
    );
}
