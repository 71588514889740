import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// import { ButtonComponent as Button } from "@components/Button";
import { Card } from "react-bootstrap";

export const RowPillarImageRight = ({ data, rowClass, imgContainerClass, contentColClass, waveType }) => {
    if (!data) {
        return null;
    }
    const heroBadgeCard = data.cardComponent && data.cardComponent.fields;

    
    return (
        <>
            {waveType && <div className="big_banner_wave"></div>}

            <Row
                className={`big-banner-row d-md-flex flex-md-row-reverse justify-content-center align-items-center m-4 ${rowClass}`}>
                <Col md={6} sm={12}>
                    <div
                        className={`big-banner-img-container d-flex justify-content-center align-items-center ${imgContainerClass}`}>
                        <Image
                            className="d-block w-100"
                            src={heroBadgeCard.image.fields.file.url}
                            alt={heroBadgeCard.image.fields.description}
                        />
                    </div>
                </Col>
                {(
                    <Col className={contentColClass} md={6} sm={12}>
                        <Card className="box-shadow card-radius">
                            <Card.Body className="d-flex flex-column">
                                <div className="big-banner-container w-75">
                                    <h2>{data.header}</h2>
                                </div>
                            </Card.Body>
                        </Card>
                        <div>&nbsp;</div>
                        <Card className="box-shadow card-radius">
                            <Card.Body className="d-flex flex-column">
                                <div className="big-banner-container w-75">
                                    <p className="big-banner-p">{heroBadgeCard.bodyText}</p>
                                    <p>
                                        <a href={heroBadgeCard.cta.fields.cta_url} style={{"text-decoration":"none", "color":"#83ccd8"}}>{heroBadgeCard.cta.fields.button}</a>
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};
