import React, { useEffect } from "react";
import { useSEO } from "@hooks/useSEO";
import { Helmet } from "react-helmet";
import { contentFulIds } from "@services/contentfulIds";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useStudents } from "hooks/students/useStudents";
import { LandingHero, ProductCarousel, RowPillar } from "@components/index";
import { BigCardWithOverlay, DoubleCards } from "components";


export function Students() {
    // CommonJS
    const data = useStudents('students');
    const seoData = useSEO(contentFulIds.REACT_APP_STUDENTS_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    let pageTitle = seoData.fields.htmlTitle === undefined ? data.pageTitle : seoData.fields.htmlTitle
    let ogImage = seoData.fields.ogImage !== undefined ? seoData.fields.ogImage.fields.file.url : ''
    let seoMetadata = {
        htmlTitle: pageTitle,
        metaDescription: seoData.fields.metaDescription !== undefined ? seoData.fields.metaDescription : '',
        canonicalTag: seoData.fields.canonicalTag !== undefined ? seoData.fields.canonicalTag : document.location,
        metaRobotsTag: seoData.fields.metaRobotsTag !== undefined ? seoData.fields.metaRobotsTag : 'index,follow',
        ogImage: 'https:' + ogImage,
        imageAltTags: seoData.fields.imageAltTags !== undefined ? seoData.fields.imageAltTags : ''
    }

    return (
        <Container className="p-0 overflow-hidden page-students" fluid>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={seoMetadata.metaDescription} />
                <link rel="canonical" href={seoMetadata.canonicalTag} />
                <meta name="robots" content={seoMetadata.metaRobotsTag} />
                <meta name="og:image" content={seoMetadata.ogImage} />
                <meta name="og:title" content={pageTitle} />
                <meta name="og:description" content={seoMetadata.metaDescription} />
            </Helmet>
            <LandingHero data={data.hero} />
            <div className="nclex-wave-1 schools-wave-1 landing-hero-wave wave-fixed"></div>
            <RowPillar data={data.rowPillarRight} type='bulleted'/>
            <ProductCarousel data={data.slider} extraWaveClass={"schools-wave-2 wave-fixed"}/>
            <BigCardWithOverlay data={data.banner} classes={{title: "w-100"}} />

            <DoubleCards type='asymetric' data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
