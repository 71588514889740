import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useContinuingEducation } from "hooks/schools/useContinuingEducation";
import { ButtonComponent as Button } from "@components/Button";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { get } from "lodash";
import {
    PageHero,
    TripleCards,
    RowPillar,
    DoubleCards,
    SeoTags,
} from "@components/index";

export function SchoolsContinuingEducation() {
    // CommonJS
    const data = useContinuingEducation();
    const seoData = useSEO(contentFulIds.REACT_APP_SCHOOLS_CEDUCATION_PAGE_SEO_ID);
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }

    let button = {
        "color": data.rowPillarRight.cardComponent.fields.cta.fields.buttonGradient,
        "url": data.rowPillarRight.cardComponent.fields.cta.fields.cta_url,
        "title": data.rowPillarRight.cardComponent.fields.cta.fields.button
    }

    data.rowPillarRight.button = button;

    return (
        <Container className="p-0 overflow-hidden page-schools-subpage page-continuing-education" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor='orange'/>
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside} />
            <center>
                <div className="top-spacer"/>
                {get(data, "tripleCardsTextOutside.cta", true) && <Button color={data.tripleCardsTextOutside.cta.fields.buttonGradient} url={data.tripleCardsTextOutside.cta.fields.cta_url} label={data.tripleCardsTextOutside.cta.fields.button} />}
            </center>
            
            <RowPillar data={data.rowPillarRight} type='rowList'/>
            <TripleCards extraWaveClass="nclex-wave-generic-1" waveType="nclex" data={data.tripleCardsTextOutside1} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric"  data={data.tripleCardsTextAsymmetric} />
            <div className="top-spacer"/>
        </Container>
    );
}
