import React, { useEffect } from "react";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useStudents } from "hooks/students/useStudents";
import { PageHero, ProductCarousel, RowPillar } from "@components";
import { 
    BigCardWithOverlay,
    DoubleCards,
    SeoTags,
} from "components";


export function StudentsRN() {
    // CommonJS
    const data = useStudents('RN');
    const seoData = useSEO(contentFulIds.REACT_APP_STUDENTS_RN_PAGE_SEO_ID);
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-rn page-students" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor="green"/>
            <div className="nclex-wave-1 schools-wave-1 wave-fixed"></div>
            <RowPillar data={data.rowPillarRight} type='bulleted'/>
            <ProductCarousel data={data.slider} extraWaveClass={"schools-wave-2 wave-fixed"}/>
            <BigCardWithOverlay data={data.banner}/>
            <DoubleCards type='asymetric' data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
