export const images = {
    mobileHeroslide1: require("../assets/mobile_hero_bg.png"),
    logoHeader: require("../assets/logo_header.png"),
    homeHeroWave1: require("../assets/waves/mobile_home_wave.png"),
    homeHeroWave2: require("../assets/waves/mobile_home_wave_2.png"),
    homeHeroWave3: require("../assets/waves/mobile_home_wave_3.png"),
    homeHeroWave4: require("../assets/waves/mobile_home_wave_4.png"),
    homeHeroWave5: require("../assets/waves/mobile_home_wave_5.png"),
    footerWave: require("../assets/waves/footer_wave.png"),
    blogWave: require("../assets/waves/blog_wave.png"),
    star:  require("../assets/mock/star.png"),
};

export const getImagesResponsive = (isDesktopOrLaptop) => {
    return {
        mobileHeroslide1: isDesktopOrLaptop ? require("") : require("../assets/mobile_hero_bg.png"),
        logoHeader: isDesktopOrLaptop ? require("../assets/logo_header.png") : require("../assets/logo_header.png"),
        homeHeroWave1: isDesktopOrLaptop
            ? require("../assets/waves/home_wave_1.png")
            : require("../assets/waves/mobile_home_wave.png"),
        homeHeroWave2: isDesktopOrLaptop
            ? require("../assets/waves/home_wave_2.png")
            : require("../assets/waves/mobile_home_wave_2.png"),
        homeHeroWave3: isDesktopOrLaptop
            ? require("../assets/waves/home_wave_3.png")
            : require("../assets/waves/mobile_home_wave_3.png"),
        homeHeroWave4: isDesktopOrLaptop
            ? require("../assets/waves/home_wave_4.png")
            : require("../assets/waves/mobile_home_wave_4.png"),
        homeHeroWave5: isDesktopOrLaptop
            ? require("../assets/waves/mobile_home_wave_5.png")
            : require("../assets/waves/mobile_home_wave_5.png"),
        footerWave: isDesktopOrLaptop
            ? require("../assets/waves/footer_wave.png")
            : require("../assets/waves/mobile_footer_wave.png"),
        blogWave: isDesktopOrLaptop ? require("../assets/waves/blog_wave.png") : require("../assets/waves/blog_wave.png"),
        nclexHeroShape: isDesktopOrLaptop
            ? require("../assets/NCLEXReview/nclex_hero_shape.png")
            : require("../assets/NCLEXReview/mobile_nclex_hero_shape.png"),
        nclexSecondWave: isDesktopOrLaptop
            ? require("../assets/NCLEXReview/nclex_wave_2.png")
            : require("../assets/NCLEXReview/mobile_nclex_wave_2.png"),
        pageHeroBg: isDesktopOrLaptop
            ? require("../assets/mock/page_hero_bg.png")
            : require("../assets/mock/page_hero_bg.png"),
        nclexQuotes: isDesktopOrLaptop
            ? require("../assets/NCLEXReview/mobile_nclex_quotes.png")
            : require("../assets/NCLEXReview/mobile_nclex_quotes.png"),
        nclexBadge: require("../assets/NCLEXReview/nclex_badge.png"),
        nclexBadgeStars: require("../assets/NCLEXReview/stars.png"),
    };
};
