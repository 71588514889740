import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CardsWithTopImage } from "components/Card";
import { useBreakPoint } from "hooks/useBreakPoint";
import Carousel from "react-bootstrap/Carousel";
import { get } from "lodash";

export const TripleCards = ({ data, centeredTitle = true, waveType = "home", extraWaveClass }) => {
    const { isMobile } = useBreakPoint();

    if (!data) {
        return null;
    }

    const waveClasses = {
        home: `home-wave-2 ${extraWaveClass}`,
        nclex: `nclex-wave-1 ${extraWaveClass}`,
    };
    const cardMap = Object.values(data);
    const result = cardMap
        .filter((item) => typeof item !== "string" && !item.fields.ctaName)
        .map((item) => {
            return { ...item.fields, image: item?.fields?.image?.fields?.file };
        });
        
    if (isMobile) {
        return (
            <>
                {waveType && <div className={waveClasses[waveType]}></div>}                
                <Row className={`triple-cards-row d-md-flex flex-md-row justify-content-center align-items-center m-2`} id={data.header.toString().toLowerCase().replaceAll(' ', '-')}>
                    <Row>
                        <h1>{data.header}</h1>
                    </Row>
                    <Carousel>
                        
                        {result &&
                            result.map((item) => {
                                return (
                                    <Carousel.Item key={item.cardComponentName} className="my-5">
                                        <CardsWithTopImage
                                            cardBodyClass="nclex-triple-card-body"
                                            simpleCardTitleClass="fw-light"
                                            title={item.header}
                                            content={item.bodyText}
                                            imgSrc={get(item, "image.url", "")}
                                            showHr={true}
                                        />
                                    </Carousel.Item>
                                );
                            })}
                    </Carousel>
                </Row>
            </>
        );
    }

    if (!centeredTitle) {
        return (
            <>
                <div className={waveClasses[waveType]}></div>
                <Row
                    id={data.header.toString().toLowerCase().replaceAll(' ', '-')}
                    className={`triple-cards-row d-md-flex flex-md-row justify-content-center align-items-center m-2 ${
                        centeredTitle ? "nclex-triple-cards-row" : {}
                    }`}>
                    <Col className={"triple-card-col"} md={3}>
                        <h1>{data.header}</h1>
                    </Col>
                    <Col className={"triple-card-col"} md={3}></Col>
                    <Col className={"triple-card-col"} md={3}></Col>
                </Row>
                <Row
                    className={`show-content d-md-flex flex-md-row justify-content-center align-items-center m-2 ${
                        centeredTitle ? "nclex-triple-cards-row" : {}
                    }`}>
                    <Row className={`grid triple-cards-container`}>
                        {result &&
                            result.map((item) => {
                                return (
                                    <Col key={item.cardComponentName} className={"triple-card-col "} md={3}>
                                        <CardsWithTopImage
                                            cardBodyClass="w-100"
                                            simpleCardClass="triple-card-simple-card"
                                            title={item.header}
                                            content={item.bodyText}
                                            imgSrc={get(item, "image.url", "")}
                                            showHr={true}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Row>
            </>
        );
    }
    return (
        <>
            <div className={waveClasses[waveType]}></div>
            
            <Row
                className={`nclex-triple-cards-row triple-cards-row d-md-flex flex-md-row justify-content-center align-items-center m-2`} id={data.header.toString().toLowerCase().replaceAll(' ', '-')}>
                <Row className="d-flex justify-content-start align-items-center less-margin-top">
                    <h1>{data.header}</h1>
                </Row>
                <Row className={`grid triple-cards-container`}>

                    {result &&
                        result.map((item) => {
                            return (
                                <Col key={item.cardComponentName} className={"triple-card-col "} md={3}>
                                    <CardsWithTopImage
                                        cardBodyClass="w-100"
                                        simpleCardClass="triple-card-simple-card"
                                        title={item.header}
                                        content={item.bodyText}
                                        imgSrc={get(item, "image.url", "")}
                                        showHr={true}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Row>
        </>
    );
};
