import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

import { SimpleCard } from "components/Card";

export const TestimonialCarousel = ({ data }) => {
    if (!data) {
        return null;
    }
    const slides = data.testimonialComponent.map((item) => item.fields);

    const renderQuote = () => (
        <div className="mb-5">
            <Image className="d-block w-100" src={require("../assets/mock/quotes.png")} alt="Quotes"/>
        </div>
    );
    return (
        <>            
            <Row className="m-1 testimonial-carousel-row">
                <Col className="text-center" md={12}>
                    <h1>{data.header}</h1>
                </Col>
                <Carousel indicators>
                    {slides &&
                        slides.map((item) => {
                            return (
                                <Carousel.Item key={item.testimonialComponentName}>
                                    <SimpleCard
                                        cardBodyClass={"justify-content-center"}
                                        cardClass={"info-carousel-card testimonial-carousel-card"}
                                        titleClass="info-title-color w-60"
                                        subtitleClass="info-subtitle font-family-secondary"
                                        cardTextClass="lh-md w-60 font-20"
                                        subtitle={"item.subtitle"}
                                        content={item.testimonialDescription}
                                        label={"item.cta.ctaName"}
                                        quote={renderQuote()}
                                        url={"item.cta.cta_url"}>
                                        <p>{`${item.contributorName} ${item.institutionInformation}`}</p>
                                    </SimpleCard>
                                </Carousel.Item>
                            );
                        })}
                </Carousel>
            </Row>
        </>
    );
};
