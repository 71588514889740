import { useTeam } from "hooks/about/useTeam";
import { useBreakPoint } from "hooks/useBreakPoint";
import { get } from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { marked } from "marked";

export const LeadershipTeamDetail = () => {
    const location = useLocation();
    const data = useTeam();

    const { isMobile } = useBreakPoint();

    if(!data){
        return null;
    }

    const member = Object.values(data.leadershipTeamGallery)
        .filter(item => get(item, "fields.imageLink.fields.cta_url", false) === location.pathname)
        .pop();

    const subtitle = get(member, "fields.leadershipPositionTitle", null);
    const title = get(member, "fields.leaderImage.fields.title", "Name");
    const description = get(member, "fields.leaderDescription", null);

    const imageUrl = get(member, "fields.leaderImage.fields.file.url", "http://placekitten.com/1000/1000");
    const altText = get(member, "fields.leaderImage.fields.description", "A kitten");

    const imageContainerClass = isMobile ? {display: "flex", justifyContent: "center"} : {};
    const imageClass = isMobile ? { maxWidth: "100%" } : { width: "100%", objectFit: "cover"};

    return(
        <div className="show-component mb-5">
            <Row className="mx-auto px-5 team-detail-container">
                <Col md={7} className="team-detail-text-container px-5">
                        <h6>{subtitle}</h6>
                        <h5 style={{fontWeight: 'bold'}}>{title}</h5>
                        <div className="body-detail-card" dangerouslySetInnerHTML={{__html: marked.parse(description)}} />
                </Col>
                <Col md={5} style={imageContainerClass} >
                    <img className="team-detail-image-container" style={imageClass} src={imageUrl} alt={altText} />
                </Col>
            </Row>
        </div>
    )
    
}