import { FooterSection } from './styles'
import { RiFacebookCircleFill, RiInstagramLine, RiYoutubeFill, RiTwitterFill } from 'react-icons/ri'
import { contentfulClient } from "@services/contentfulClient";
import { useState, useEffect } from "react";
import { contentFulIds } from "@services/contentfulIds";

export async function getRootData(id) {
    return await contentfulClient.getEntry(id)
}

export function FooterComponent() {

    const [logo, setLogo] = useState('');
    const [bottomFooterText, setBottomFooterText] = useState('');
    const [columnMenuLinks, setColumnMenuLinks] = useState([]);

    useEffect(() => {
        (async () => {

            let rootData = await getRootData(contentFulIds.REACT_APP_HOME_PAGE_ID)
            let itemsFooter = rootData.fields.footer;
            let footerContent = await getRootData(itemsFooter['sys']['id'])
            let arrColumn1 = []
            let arrColumn2 = []
            let arrColumn3 = []
            let arrColumn4 = []

            let columnMenuLinks = {}

            for (var i=0; i<footerContent['fields']['column1MenuLinks'].length; i++) {
                arrColumn1.push(await getRootData(footerContent['fields']['column1MenuLinks'][i]['sys']['id']))
            }

            arrColumn2 = await getRootData(footerContent.fields.column2MenuLinks.sys.id)
            arrColumn3 = await getRootData(footerContent.fields.column3MenuLinks.sys.id)
            arrColumn4 = await getRootData(footerContent.fields.column4Subscribe.sys.id)
         
            columnMenuLinks = {
                column1MenuLinks: arrColumn1,
                column2MenuLinks: arrColumn2,
                column3MenuLinks: arrColumn3,
                column4Subscribe: arrColumn4,
            }

            setLogo('https:' + itemsFooter['fields']['siteLogo']['fields']['file']['url'])
            setBottomFooterText(itemsFooter['fields']['bottomFooterText'])
            setColumnMenuLinks(columnMenuLinks)
        })()
    }, []);

    if(columnMenuLinks !== undefined && columnMenuLinks['column1MenuLinks'] !== undefined) {
        return (
            <FooterSection className='footer'>
                <section>
                    <div>
                        <div className='icons'>
                            <h1>
                                {columnMenuLinks['column1MenuLinks'][0]['fields']['header']}
                            </h1>
                            <div>
                                <div className="list-item justify-content-center d-flex align-items-center">
                                    <a href={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][0]['fields']['cta_url']} target="_blank" rel="noreferrer">
                                        <RiFacebookCircleFill size="1.75em" title={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][0]['fields']['cta_url']} aria-describedby={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][0]['fields']['cta_url']}>
                                            <title>{columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][0]['fields']['cta_url']}</title>
                                        </RiFacebookCircleFill>
                                    </a>
                                </div>
                                <div className="list-item">
                                    <a href={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][1]['fields']['cta_url']} target="_blank" rel="noreferrer">
                                        <RiInstagramLine size="1.75em" title={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][1]['fields']['cta_url']} aria-describedby={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][1]['fields']['cta_url']}>
                                            <title>{columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][1]['fields']['cta_url']}</title>
                                        </RiInstagramLine>   
                                    </a>
                                </div>
                                <div className="list-item">
                                    <a href={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][2]['fields']['cta_url']} target="_blank" rel="noreferrer">
                                        <RiYoutubeFill size="1.75em" title={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][2]['fields']['cta_url']} aria-describedby={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][2]['fields']['cta_url']}>
                                            <title>{columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][2]['fields']['cta_url']}</title>
                                        </RiYoutubeFill>
                                    </a>
                                </div>
                                <div className="list-item">
                                    <a href={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][3]['fields']['cta_url']} target="_blank" rel="noreferrer">
                                        <RiTwitterFill size="1.75em" title={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][3]['fields']['cta_url']} aria-describedby={columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][3]['fields']['cta_url']}>
                                            <title>{columnMenuLinks['column1MenuLinks'][0]['fields']['cta'][3]['fields']['cta_url']}</title>
                                        </RiTwitterFill>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='resources'>
                            <h1>
                                {columnMenuLinks['column1MenuLinks'][1]['fields']['header']}
                            </h1>
                            <div>
                                <div>
                                    <a href={columnMenuLinks['column1MenuLinks'][1]['fields']['cta'][0]['fields']['cta_url']}>
                                        {columnMenuLinks['column1MenuLinks'][1]['fields']['cta'][0]['fields']['button']}
                                    </a>
                                </div>
                                <div>
                                    <a href={columnMenuLinks['column1MenuLinks'][1]['fields']['cta'][1]['fields']['cta_url']}>
                                        {columnMenuLinks['column1MenuLinks'][1]['fields']['cta'][1]['fields']['button']}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='company'>
                        <h1>
                            {columnMenuLinks['column2MenuLinks']['fields']['header']}
                        </h1>
                        <div className='company'>
                            <div>
                                <a href={columnMenuLinks['column2MenuLinks']['fields']['cta'][0]['fields']['cta_url']}>
                                    {columnMenuLinks['column2MenuLinks']['fields']['cta'][0]['fields']['button']}
                                </a>
                            </div>
                            <div>
                                <a href={columnMenuLinks['column2MenuLinks']['fields']['cta'][1]['fields']['cta_url']}>
                                    {columnMenuLinks['column2MenuLinks']['fields']['cta'][1]['fields']['button']}
                                </a>
                            </div>
                            <div>
                                <a href={columnMenuLinks['column2MenuLinks']['fields']['cta'][2]['fields']['cta_url']}>
                                    {columnMenuLinks['column2MenuLinks']['fields']['cta'][2]['fields']['button']}
                                </a>
                            </div>
                            <div>
                                <a href={columnMenuLinks['column2MenuLinks']['fields']['cta'][3]['fields']['cta_url']}>
                                    {columnMenuLinks['column2MenuLinks']['fields']['cta'][3]['fields']['button']}
                                </a>
                            </div>
                            <div>
                                <a href={columnMenuLinks['column2MenuLinks']['fields']['cta'][4]['fields']['cta_url']}>
                                    {columnMenuLinks['column2MenuLinks']['fields']['cta'][4]['fields']['button']}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='supportFooter'>&nbsp;</div>
                    <div>
                        <h1>
                            {columnMenuLinks['column3MenuLinks']['fields']['header']}
                        </h1>
                        <div className="support">
                            <div>
                                <a href={columnMenuLinks['column3MenuLinks']['fields']['cta'][0]['fields']['cta_url']}>
                                    {columnMenuLinks['column3MenuLinks']['fields']['cta'][0]['fields']['button']}
                                </a>
                            </div>
                            <div style={{lineHeight:"10px"}}>
                                <a href={columnMenuLinks['column3MenuLinks']['fields']['cta'][1]['fields']['cta_url']}>
                                    {columnMenuLinks['column3MenuLinks']['fields']['cta'][1]['fields']['button']}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='mail'>
                        <p>
                            {columnMenuLinks['column4Subscribe']['fields']['header']}
                        </p>
                        <form action="https://chloe.insightly.services/Forms/MTA3MzgwOC05MzYyLTE4ODU1MDI%3d" method="post" encType="multipart/form-data">
                            <input type="email" id="email" name="email" placeholder={columnMenuLinks['column4Subscribe']['fields']['subscribeEMailField']} required />
                            <button type="submit" value="Submit" className="newsletter-sub-btn">
                                {columnMenuLinks['column4Subscribe']['fields']['cta'][0]['fields']['button']}
                            </button>
                        </form>
                    </div>
                </section>
                <img src={logo} alt="Hurst Logo"></img>
                <p>
                    {bottomFooterText}
                </p>
            </FooterSection>
        );
    } else {
        return (<FooterSection></FooterSection>)
    }
}