import { useEffect, useState } from "react";
import { getDataByField } from "@services/contentfulClient";

export const useHeader = () => {
    const [header, setHeader] = useState();
    useEffect(() => {
        (async () => {
            const response = await getDataByField({
                content_type: "header",
                "fields.headerName": "Header",
                include: 10,
            });
            const data = response.items[0];
            if (data) {
                setHeader(data.fields.topnavbar[0].fields.navLinkComponent);
            }
        })();
    }, []);
    return header;
};
