import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import TagManager from 'react-gtm-module'
import 'bootstrap/dist/css/bootstrap.min.css';

import "./styles/main.css";
import "./styles/exitintentpopup.css";

const tagManagerArgs = {
    gtmId: 'GTM-KWCB47'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
);