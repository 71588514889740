import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useTestimonials } from "hooks/schools/useTestimonials";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import {
    PageHero,
    SimpleCardWithButton,
    SeoTags,
} from "@components/index";
import { FilterTestimonials } from "components/FilterTestimonials";
import { TestimonialBanner } from "components/TestimonialBanner";

export function Testimonials() {
    const [selectedCategory, setSelectedCategory] = useState('STUDENTS');
    const data = useTestimonials();
    const seoData = useSEO(contentFulIds.REACT_APP_SCHOOLS_TESTIMONIALS_PAGE_SEO_ID);
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }

    let dataTestimonial = data.testimonial6CardsDisplay

    let filteredByCategory = {
        dropdownName: dataTestimonial.testimonial6CardsDisplayName,
        testimonialComponent: []
    }

    if(selectedCategory !== undefined && selectedCategory !== ""){
        for (let i=0; i<data.testimonial6CardsDisplay['testimonial6CardsDisplay'].length; i++){
                
            if(data.testimonial6CardsDisplay['testimonial6CardsDisplay'][i] !== undefined && data.testimonial6CardsDisplay['testimonial6CardsDisplay'][i]['fields']['testimonialCategory'] && data.testimonial6CardsDisplay['testimonial6CardsDisplay'][i]['fields']['testimonialCategory']['fields']['testimonialCategory'] === selectedCategory){
                filteredByCategory['testimonialComponent'].push(dataTestimonial['testimonial6CardsDisplay'][i])
            }
        }
        dataTestimonial = filteredByCategory
    }

    return (
        <Container className="p-0 overflow-hidden page-schools-subpage page-testimonials" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor='orange'/>
            <div className="nclex-wave-1 schools-testimonials-wave" ></div>
            <FilterTestimonials
                selectedCategory={selectedCategory}
                data={data.testimonialCategorySelector}
                setSelectedCategory={setSelectedCategory}
             />
              {dataTestimonial.testimonialComponent.map(item => <TestimonialBanner data={item} />)}
              <div className="top-spacer"/>
            <SimpleCardWithButton cardClass="w-80" rowClass={"d-flex justify-content-center align-items-center nclex-simple-card-button-row my-5"} data={data.banner} />
        </Container>
    );
}
