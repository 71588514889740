import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { HorizontalBanner, TripleCards, BigBanner, PageHero, DoubleCards } from "@components/index";
import { useNstream } from "@hooks/nclexreview/useNStream";
import { SeoTags } from "@components/index";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";

export function NStream() {
    // CommonJS
    const data = useNstream();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_NSTREAM_PAGE_SEO_ID);
    useEffect(() => {
        pageView(window.location.href);
    }, []);


    if (!data || !seoData) {
        return null;
    }


    return (
        <Container className="p-0 overflow-hidden" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
           
            
            <TripleCards  waveType="nclex" data={data.tripleCardsTextOutside} extraWaveClass="nclex-wave-big-screen wave-fixed"/>
            {data.banner ? (
                <HorizontalBanner
                    
                    buttonColClass="nclex-horizontal-button-col d-flex my-3"
                    rowClass="nclex-horizontal-banner-row"
                    data={data.banner}
                />
                ) : (
                    <span/>
            )}
            <BigBanner
                imgContainerClass="nclex-bigbanner-img-container"
                reverse
                extradata={data.rowPillarLeft}
                data={data.heroBadge}
            />
            <DoubleCards rowClass={"nclex-double-card-row"}  extraWaveClass="nclex-wave-generic-2 wave-fixed" waveType="nclex" type="carousel" data={data.tripleCards} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric"  data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
