import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { SimpleCard } from "components/Card";
import { parseText } from "utils/parse";

export const VerticalBanner = ({ data }) => {
    if (!data) {
        return null;
    }
    
    return (
        <Row className="vertical-banner-row d-flex flex-column justify-content-center align-items-center p-3">
            <Col>
                <div>
                    <Image className="d-block w-100" src={require("@assets/mock/success_banner.png")} alt="Success Banner"/>
                </div>
            </Col>
            <Col className="d-flex flex-column justify-content-center align-items-center" md={9}>
                <SimpleCard
                    cardClass="vertical-banner-card"
                    titleClass="h1 font-weight-normal"
                    cardBodyClass="lh-sm w-100"
                    content={parseText(data.bodyText.split("[break]").join("<br />"))}
                />
            </Col>
        </Row>
    );
};
