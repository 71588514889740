import React from "react";
import { ButtonComponent as Button } from "@components/Button";
import Image from "react-bootstrap/Image";
import { useBreakPoint } from "hooks/useBreakPoint";

const LandingHeroShape = ({ type, shapeColor = "purple" }) => {
    const { break768 } = useBreakPoint();
    const isLanding = type === "landing";
    if (!isLanding) {
        return null
    }

    const shapeName = `${break768 ? "mobile_" : ""}${shapeColor}_shape`;
    return (
        <div className="hero-shape-container position-absolute z-1">
            <Image className="d-block w-100" src={require(`../assets/mock/${shapeName}.png`)} alt="Coloured Shape"/>
        </div>
    );
};

export const HeroContent = ({
    title,
    bodyText,
    url,
    label,
    containerClass,
    type,
    shapeColor = "purple",
    subheadline,
    url2 ="",
    label2="",
    id1 = null,
    id2 = null
}) => {
    const isLanding = type === "landing";

    return (
        <div className={`position-relative ${containerClass}`}>
            <div className={`z-2 position-relative  ${isLanding ? "landing-content-container" : ""}`}>
                {title && <h1 className="text-white z-2 position-relative">{title}</h1>}
                {subheadline && <h5 className="text-white position-relative z-2 hero-content-h5">{subheadline}</h5>}
                {bodyText && (
                    <p
                        className={`text-white z-2 position-relative ${
                            isLanding ? "landing-hero-text-box" : ""
                        }`}>
                        {bodyText}
                    </p>
                )}
                <div className="custom-button-container">
                    {url && (
                        <div className="position-relative z-2">
                            <Button url={url} label={label} id={id1} />
                        </div>
                    )}
                    {url2 && (
                        <div className="position-relative z-2 ms-3">
                            <Button url={url2} label={label2} id={id2}/>
                        </div>
                    )}
                </div>
                
            </div>
           <LandingHeroShape shapeColor={shapeColor} type={type} />
        </div>
    );
};
