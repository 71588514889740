import React from "react";
import Carousel from "react-multi-carousel";
import { CardWithButton } from "components/Card";
import { parseText } from "@utils/parse";
import { get } from "lodash";
import Row from "react-bootstrap/Row";
import "react-multi-carousel/lib/styles.css";
import { useBreakPoint } from "hooks/useBreakPoint";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

export const ProductCarousel = ({ data, extraWaveClass = '' }) => {
    const { isMobile } = useBreakPoint();
    const slides = data.sliderComponent.map((slide) => slide.fields); 
    const textWidth = isMobile ? "w-100" : "w-60";

    return (
        <>
            <div className={`nclex-wave-1 ${extraWaveClass}`} style={{height: "250%"}}></div>
            <Row className="m-3 product-carousel-row">
                <h1 className="text-center my-5">{data.header}</h1>
                <Carousel swipeable={true} responsive={responsive}>
                    {slides.map((item) => {
                        return (
                            <CardWithButton
                                key={item.header}
                                cardClass={"product-carousel-card m-2"}
                                titleClass="info-title-color"
                                subtitleClass="info-subtitle font-family-secondary"
                                cardTextClass={`${textWidth} font-20 card-text-height d-flex align-items-center justify-content-center flex-column`}
                                priceClass="lh-md w-100"
                                title={item.header}
                                subtitle={item.subtitle}
                                content={parseText(item.bodyText)}
                                label={get(item, "cta.fields.button", "")}
                                price={{
                                    usa: [item.price1, item.price2].filter((price) => price),
                                    canada: item.price1Canada,
                                }}
                                url={get(item, "cta.fields.cta_url")}
                            />
                        );
                    })}
                </Carousel>
            </Row>
        </>
    );
};
