import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "components";
import { useTeam } from "hooks/about/useTeam";
import { LeadershipTeamGallery } from "components/LeadershipTeamGallery";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { SeoTags } from "@components/index";

export function Team() {
    // CommonJS
    const data = useTeam();
    const seoData = useSEO(contentFulIds.REACT_APP_ABOUT_LEADERSHIP_TEAM_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-team" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <div className="nclex-wave-1 team-wave-1" >
            </div>
            <LeadershipTeamGallery data={data.leadershipTeamGallery} />
        </Container>
    );
}
