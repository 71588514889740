import styled from "styled-components"; 

export const FooterSection = styled.footer`
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
    

    section {
        text-align: center;

        @media screen and (min-width: 1200px) {
            display: flex;
            justify-content: space-between;
            text-align: left;
        }

        a {
            text-decoration: none;
            color: #707070;
            font-size: 25px;
            font-family: Montserrat;
            font-weight: normal;
        }

        div {
            h1 {
                font-weight: 600;
                font-size: 22px;
                margin-bottom: .5rem;
                color: ${(props) => props.theme["black500"]};
            }
            
            .icons {

                
                div {
                    display: flex;
                    margin-top: 1rem;
                    place-content: center;
                    justify-content: center;
                    

                    .list-item {
                        margin: 0.5rem;
                        a {
                            display: flex;
                            padding: 5px;
                            font-size: 1.5rem;
                            border-radius: 50%;
                            border: 1px solid transparent;
                            box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.3);
                            transition: .3s;
                            color: #707070;
                            height: 50px;
                            width: 50px;
                            
                            &:hover,
                            &:active,
                            &:focus {
                                cursor: pointer;
                                box-shadow: none;
                                border-color: ${(props) => props.theme["black500"]};
                            }

                        }
                    } 

                    li:last-child {
                        margin-right: 0;
                    }
                }
            }

            @media only screen and (max-width: 767px) {
                .icons {
                    margin-bottom: 50px;
                }
                .resources {
                    margin-bottom: 50px;
                    line-height: 23px;
                }
                .company {
                    line-height: 23px;
                }
                .support {
                    line-height: 23px;
                }
                .supportFooter {
                    padding-top: 50px !important;
                    margin-bottom: 50px !important;
                }
            }

            div, 
            p {
                font-weight: 300;
                font-size: 13px;
                font-family: Montserrat;
                margin-bottom: 1rem;
                list-style-type: none; 
                color: ${(props) => props.theme["black500"]};
                
                .list-item {
                    margin-bottom: .5rem;
                    
                    &:hover,    
                    &:active,
                    &:focus {
                        a {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            &.mail {
                max-width: 100%;
                text-align: left;
                padding: 2rem 0;
                
                @media screen and (min-width: 1200px) {
                    max-width: 30%;
                    padding: 0;
                }

                form {
                    display: flex;
                    flex-direction: column;

                    input {
                        border: 0;
                        padding: 1rem;
                        margin-bottom: 1rem;
                        border-radius: 20px;
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
                    }

                    button {
                        border: 0;
                        font-weight: 300;
                        padding: .6rem;
                        font-size: 1.0rem;
                        border-radius: 15px;
                        text-transform: uppercase;
                        border: 1px solid transparent;
                        color: ${(props) => props.theme["black500"]};
                        box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.3);
                        background-color: ${(props) => props.theme["white"]};
                        transition: .3s;

                        &:hover,
                        &:active,
                        &:focus {
                            cursor: pointer;
                            box-shadow: none;
                            border-color: ${(props) => props.theme["black500"]};
                        }
                    }
                }
            }
        }
    }

    img {
        max-width: 200px;
        padding-top: 3rem;
        
        @media screen and (min-width: 1200px) {
            max-width: none;
            padding-top: 0;
        }
    }

    > p {
        font-weight: 300;
        margin: 2rem;
        list-style-type: none; 
        color: ${(props) => props.theme["black500"]};
    }
`;