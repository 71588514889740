// TODO: change the fallback to production
const environment = "development";

const idsDev = {
    REACT_APP_HOME_PAGE_ID: "1o6z69AjDUVeksLXJ5hb3h",
    REACT_APP_HOME_PAGE_SEO_ID: "hHwE4rZgWg3DiK0F8VNaS",
    REACT_APP_CONTACTFORM_PAGE_SEO_ID: "DRO5O1ppwFKWCyQVQk8dp",
    REACT_APP_CONTACTFORM_PAGE_ID: "4jmtg2JgaU3rYwtvhVmzFd",

    REACT_APP_ABOUT_PAGE_ID: "b1td0QvsZf5ZE1dfTsYsp",
    REACT_APP_ABOUT_PAGE_SEO_ID: "7K0LmPtKLQhOW8wd1EFFyF",
    REACT_APP_ABOUT_LEADERSHIP_TEAM_PAGE_ID: "6Wu769drTOnMUmtB9Ub4Pq",
    REACT_APP_ABOUT_LEADERSHIP_TEAM_PAGE_SEO_ID: "1XomSWqRGaMJBuY2liTrJk",
    REACT_APP_ABOUT_GUARANTEE_PAGE_ID: "2OJXofDVsKnf1A8955vukn",
    REACT_APP_ABOUT_GUARANTEE_PAGE_SEO_ID: "2GcQIvJ4p9NlUB1j5U4thb",
    REACT_APP_ABOUT_PAYMENT_PAGE_ID: "5aPRB5IafQ7tq3QUEvzh0M",
    REACT_APP_ABOUT_PAYMENT_PAGE_SEO_ID: "3WGxiZ2dSzTVv2aMHflMnL",

    REACT_APP_NCLEX_PAGE_ID: "4BlC0LQ8ylQdXOeGpMzJvl",
    REACT_APP_NCLEX_PAGE_SEO_ID: "6DitDEbBp8rEN9s7ukTJnX",
    REACT_APP_NCLEX_LIVE_PAGE_ID: "1sEnEDUHndDGDjfYuAUtPP",
    REACT_APP_NCLEX_LIVE_PAGE_SEO_ID: "5fHIpVTrKOykpEQkXbM5cU",
    REACT_APP_NCLEX_PLUS_PAGE_ID: "gbSb7xuWCOoVeh5L0VMtt",
    REACT_APP_NCLEX_PLUS_PAGE_SEO_ID: "S1Lsc1zTyD7OnbXUKbfdL",
    REACT_APP_NCLEX_NOW_PAGE_ID: "1eO1Zp60VvvGyH4cjNVjTk",
    REACT_APP_NCLEX_NOW_PAGE_SEO_ID: "2VNRdIl6vO9dl7L7aYINxE",
    REACT_APP_NCLEX_NSTREAM_PAGE_ID: "6u963exQzgzy0Ykm8w5ySc",
    REACT_APP_NCLEX_NSTREAM_PAGE_SEO_ID: "3cT7Rf8TgIFVy9lkpzATX7",
    REACT_APP_NCLEX_NEXT_PAGE_ID: "51er8AvylIRiuw2Bv5bRJk",
    REACT_APP_NCLEX_NEXT_PAGE_SEO_ID: "7DZpP9oJVqLkLfz7pXcGg4",
    REACT_APP_NCLEX_TOTALPASS_PAGE_ID: "6e0qm8UEs8Jo4Vqu68ztuy",
    REACT_APP_NCLEX_TOTALPASS_PAGE_SEO_ID: "ePqimz57RzJWaLfC3OSRv",
    REACT_APP_NCLEX_ADDONS_PAGE_ID: "NawlAJrTulOzc0gG03mCX",
    REACT_APP_NCLEX_ADDONS_PAGE_SEO_ID: "5Bjrjp0fJqaLrj7qchePUW",
    REACT_APP_NCLEX_ELEVATE_PAGE_ID: "l2fF6GC1Aoz7dHvXIUlcT",
    REACT_APP_NCLEX_ELEVATE_PAGE_SEO_ID: "4uamQKCMaFWmxD84AdaqpA",

    REACT_APP_STUDENTS_PAGE_ID: "MMyF5TgiQtpqXaX13Vxu6",
    REACT_APP_STUDENTS_PAGE_SEO_ID: "3qI5C4CJSTXgNIPZtEXuzf",
    REACT_APP_STUDENTS_PN_PAGE_ID: "2hoXfOhn0SkxwlSi9O7cOx",
    REACT_APP_STUDENTS_PN_PAGE_SEO_ID: "6Z4jDkqSgaBW3jtDpXUtgp",
    REACT_APP_STUDENTS_RN_PAGE_ID: "49lWxE8SZ9ZGwAGPCOQ2SZ",
    REACT_APP_STUDENTS_RN_PAGE_SEO_ID: "2diWVOcX1AGkYCk89eAnOD",
    

    REACT_APP_SCHOOLS_PAGE_ID: "1k4A3bEARrsKGHkGbpC6hc",
    REACT_APP_SCHOOLS_PAGE_SEO_ID: "1E9cSqhknTpqZV7ZbPlg4c",
    REACT_APP_SCHOOLS_PARTNERSHIP_PAGE_ID: "4wat01qa4k0OWPAWQRTXRi",
    REACT_APP_SCHOOLS_PARTNERSHIP_PAGE_SEO_ID: "5m7njWkk4bkmO1yWolbc8G",
    REACT_APP_SCHOOLS_TESTIMONIALS_PAGE_ID: "30Y4Lrfx7Q2TkaInTZ1w5W",
    REACT_APP_SCHOOLS_TESTIMONIALS_PAGE_SEO_ID: "4rzbS6nSyHonmIIn9R5rP9",
    REACT_APP_SCHOOLS_CEDUCATION_PAGE_ID: "3bboO4pCZ9yiivkr7Sl7co",
    REACT_APP_SCHOOLS_CEDUCATION_PAGE_SEO_ID: "4edR87za2CcmB9Y7Ea1WVZ",
    REACT_APP_SCHOOLS_CONTACT_PAGE_ID: "1RPR1RP8AGudtaGMuDWkx7",
    REACT_APP_SCHOOLS_CONTACT_PAGE_SEO_ID: "30iuVMlfrFa04o0wxat1h5",
    REACT_APP_SCHOOLS_ONLINECLASSROOM_PAGE_ID: "4eeCYuD6sqLOowxMytFDHX",
    REACT_APP_SCHOOLS_ONLINECLASSROOM_PAGE_SEO_ID: "4uWYNOVpdHoTZr4K99JFXq",

    REACT_APP_HEALTHCARE_PAGE_ID: "2sPHIZvyrK9MAY2bCgUlyv",
    REACT_APP_HEALTHCARE_PAGE_SEO_ID: "1iotDCSTqmO63iauMXbScE",

    REACT_APP_RESOURCES_PAGE_ID: "Hcp0onp4eB7vT40bCCklX",
    REACT_APP_RESOURCES_PAGE_SEO_ID: "5MqgPLINd5yeS85deGo7yo",
    REACT_APP_RESOURCES_FAQ_PAGE_ID: "5lsD2tuLezU6GpMIv40cIy",
    REACT_APP_RESOURCES_FAQ_PAGE_SEO_ID: "1koaMgx7O6GJbNMmpsuW4m",
    REACT_APP_RESOURCES_SH_PAGE_ID: "leI1PbxLnczvNh9Jxe0iv",
    REACT_APP_RESOURCES_GLOSSARY_PAGE_ID: "43dL0aXCxiahIi6S7QjPEG",
    REACT_APP_RESOURCES_SH_PAGE_SEO_ID: "4ZZ2Xfgk6RABewLEKLoVv2",

    REACT_APP_COMMUNITY_PAGE_ID: "5Sdp7mTDMA9BaptqhQ3SzO",
    REACT_APP_COMMUNITY_PAGE_SEO_ID: "by8Ct3visuA6TkI8xbtz0",
    //REACT_APP_COMMUNITY_BLOG_PAGE_ID: "1K2MAC7KFEOk0OfQn9b7L8",
    REACT_APP_COMMUNITY_BLOG_HERO_ID: "63k4wFE6fNToC5SEcsN7AN",
    REACT_APP_COMMUNITY_BLOG_PAGE_SEO_ID: "3w4aERmegIw8kjkVJKOG3E",
    REACT_APP_AMBASSADORS_PAGE_ID: "29YzRfwsWXWnsHhRxrO7e8",
    REACT_APP_AMBASSADORS_PAGE_SEO_ID: "63CfKFF4IquO2VG41WA0Dd",
    REACT_APP_SOCIAL_PAGE_ID: "32svxGKe81TQ67d8Z3kExV",
    REACT_APP_SOCIAL_PAGE_SEO_ID: "3SVOmIcolwlCVFyCCMxpu1",

    REACT_APP_AMBASSADOR1_ID: "5dF8I9sAwGyMca3hsjLybE",
    REACT_APP_AMBASSADOR2_ID: "mjCPgYzUMjtE1yRCWRdAO",
    REACT_APP_AMBASSADOR3_ID: "2PQykwHlK5aySXDM6bIU5y",
    REACT_APP_AMBASSADOR4_ID: "4GZtwhkTW7PQxZWLo6iOX8",

    REACT_APP_CAREERS_PAGE_ID: "29zxM8WhheLpIlqDz4ho70",
    REACT_APP_CAREERS_PAGE_SEO_ID: "7aYJVl7xuRVuQ7ejHvyDAd",

    REACT_APP_CONTACT_PAGE_ID: "29zxM8WhheLpIlqDz4ho70",
    REACT_APP_CONTACT_PAGE_SEO_ID: "7aYJVl7xuRVuQ7ejHvyDAd",
    REACT_APP_CONTACT_API_URL: "https://www.hurstreview.com/api/regional-directors"
};

const idsStg = {};
const idsProd = {};

const currentIds = {
    development: idsDev,
    production: idsStg,
    staging: idsProd,
};

export const contentFulIds = currentIds[environment];
