import { get } from "lodash";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const LeadershipTeamCard = ({ data }) => {
    const detailPageUrl = get(data, "fields.imageLink.fields.cta_url", null);
    const subtitle = get(data, "fields.leadershipPositionTitle", null);
    const title = get(data, "fields.leaderImage.fields.title", "Name");

    const imageUrl = get(data, "fields.leaderImage.fields.file.url", "http://placekitten.com/1000/1000");
    const altText = get(data, "fields.leaderImage.fields.description", "A kitten");

    return (
        <Col s={12} md={6} lg={4} className="my-2">
            <a href={detailPageUrl} style={{ textDecoration: "none" }}>
                <div className="leadership-card">
                    <div className="ratio ratio-1x1">
                        <Card.Img variant="top" src={imageUrl} alt={altText} />
                    </div>
                    <Card.Body>
                        <Card.Subtitle>{subtitle}</Card.Subtitle>
                        <Card.Title style={{ fontWeight: "bold" }}>{title}</Card.Title>
                    </Card.Body>
                </div>
            </a>
        </Col>
    );
};

export const LeadershipTeamGallery = ({ data }) => {
    const header = get(data, "header", null);
    const cards = Object.values(data).filter((item) => {
        return typeof item !== "string";
    });

    return (
        <div className="leadership-team-gallery-container">
            <Col className="text-center">
                <h1>{header}</h1>
            </Col>
            <Row className="leadership-container">
                {cards.map((card, index) => {
                    return <LeadershipTeamCard data={card} key={index} />;
                })}
            </Row>
        </div>
    );
};
