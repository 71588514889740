import { useEffect, useState } from "react";
import { getDataById } from "@services/contentfulClient";
import { contentFulIds } from "@services/contentfulIds";

export const useOnlineClassroom = () => {
    const [data, setData] = useState();
    useEffect(() => {
        (async () => {
            const data = await getDataById(contentFulIds.REACT_APP_SCHOOLS_ONLINECLASSROOM_PAGE_ID);
            const current = data.items[0];

            if (current) {
                const pageContent = current.fields.pageContent;
                let result = {};
                let count = 1;
                pageContent.forEach((item) => {
                    const fields = item.fields;

                    if(fields) {
                        const homeKeys = Object.keys(fields).filter((item) => item.includes("Name"));
                        
                        if(!Object.keys(result).includes(homeKeys[0].replace("Name", ""))) {
                            result = Object.assign(result, {
                                [homeKeys[0].replace("Name", "")]: item.fields,
                            });
                        } else {
                            result = Object.assign(result, {
                                [homeKeys[0].replace("Name", "") + count]: item.fields,
                            });
                            count = count + 1;
                        }
                    }
                });

                setData(result);
            }
        })();
    }, []);
    return data;
};
