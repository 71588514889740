import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { RowPillar } from "@components";
import { PageHero } from "components";
import { useAmbassadors } from "hooks/community/useAmbassadors";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import {
    DoubleCards, SimpleCardWithButton, SeoTags
} from "@components/index";
import { useBreakPoint } from "hooks/useBreakPoint";

export function Ambassadors() {
    // CommonJS
    const data = useAmbassadors();
    const seoData = useSEO(contentFulIds.REACT_APP_AMBASSADORS_PAGE_SEO_ID);
    const {smallScreen} = useBreakPoint();

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-ambassadors" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} shapeColor='orange'/>
            <div className="nclex-wave-1 community-wave-1"></div>
            <div className="home-wave-2 wave-reverse community-first-wave"></div>
            <RowPillar data={data.rowPillarRight} type='quote'/>
            <div className="home-wave-2 ambassadors-wave-2"></div>
            <RowPillar data={data.rowPillarLeft} type='quote' reverse="true"/>
            <div className="home-wave-2 wave-reverse ambassadors-wave-3"></div>
            <RowPillar data={data.rowPillarRight1} type='quote'/>
            <DoubleCards rowClass="show-content" type="ambassadors" data={data.tripleCards} />
            <SimpleCardWithButton cardClass="w-80" rowClass={"d-flex justify-content-center align-items-center nclex-simple-card-button-row my-5"} data={data.banner} waveStyle={smallScreen ? {} : {height: "100%"}}/>
        </Container>
    );
}
