import React from "react";
import Carousel from "react-multi-carousel";
import Row from "react-bootstrap/Row";
import "react-multi-carousel/lib/styles.css";
import { ButtonComponent as Button } from "@components/Button";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

export const FilterCarousel = (props) => {
    
    const filterItems = props.data.faqCategory.map(item => item.fields);

    const onClickCategory = item => {
        if (props.selectedCategory === item.faqCategory) {
            props.setSelectedCategory('');
        }
        else {
            props.setSelectedCategory(item.faqCategory);
        }
    };

    return (
        <>
            <Row className="m-3 filter-carousel-row">
                <Carousel swipeable={true} responsive={responsive}>
                    {filterItems.map((item, index) => {
                        return (
                            <div onClick={() => onClickCategory(item)}>
                                <Button
                                    active={props.selectedCategory === item.faqCategory}
                                    containerClass={`horizontal-filter-button ${props.selectedCategory === item.faqCategory ? "" : "" }`}
                                    key={item.faqCategory+index}
                                    selectedElement={props.selectedCategory}
                                    label={item.faqCategory} 
                                    hasOnclick='block'
                                    hasUrl='bock'
                                    color={props.selectedCategory === item.faqCategory ? "green" : "purple"}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </Row>
        </>
    );
};
