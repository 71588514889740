import React from "react";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Carousel from "react-multi-carousel";
import { useBreakPoint } from "@hooks/useBreakPoint";
import { Col } from "react-bootstrap";
import { get } from "lodash";


export const ImagesGallery = ({ data }) => {
    const { smallScreen } = useBreakPoint();
    if (!data) {
        return null
    }

    const responsive = {
        smallScreen: {
            breakpoint: { max: 1024, min: 0 },
            items: 1,
        }
    };
    if(smallScreen){
        return(
            <>
            <Carousel
                partialVisible={true}
                infinite={true}
                swipeable={true}
                responsive={responsive}>
                {Object.values(data).map((image, key) => {
                    const imageUrl = get(image, "fields.file.url", null);
                    const altImage = get(image, "fields.description", null)

                    if (typeof image === 'string'){
                        return null;
                    }
                    else {
                        return (
                            <>
                            <div key={key}>
                                <Image
                                    className="show-content d-block w-100"
                                    src={imageUrl}
                                    alt={altImage}
                                    />
                            </div>
                            
                            </>      
                        )
                    }
                })}
            </Carousel>
            </>
        )
    }
    return (
        <>
            <Row className="w-80 mx-auto align-items-center gallery-row">

                {Object.values(data).map((image, key) => {
                    const imageUrl = get(image, "fields.file.url", null);
                    const altImage = get(image, "fields.description", null)

                    if (typeof image === 'string'){
                        return null;
                    }
                    else {
                        return (
                            <Col lg={4} md={6} sm={12} key={key} className="mt-2" style={{overflow: 'clip'}}>
                                <div>
                                    <Image
                                        className="d-block w-100"
                                        src={imageUrl}
                                        alt={altImage}
                                        />
                                </div>
                            </Col>);
                    }
                })}
            </Row>
        </>
    )
};