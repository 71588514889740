import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Image from "react-bootstrap/Image";
import { useBreakPoint } from "@hooks/useBreakPoint";
import { HeroContent } from "./HeroContent";
// import { images } from "styles/images";
import { get } from "lodash";

const datamock = {
    header: "N-STREAM",
    bodyText: "Three-day live-streamed review to reinforce learnings from the comfort of your home",
    url: "/",
    label: "ENROLL NOW"
}

export const PageHero = ({  data = datamock, shapeColor = "purple" }) => {
    const { isMobile } = useBreakPoint();
    const banner = false;
    // const starCount = 5;
    const bodyText = get(data, "cardComponent.fields.bodyText", "");
    const header = get(data, "cardComponent.fields.header", "");
    const label = get(data, "cardComponent.fields.cta.fields.button", "")
    const url = get(data, "cardComponent.fields.cta.fields.cta_url", "")
    const backgroundImg = get(data, "cardComponent.fields.image.fields.file.url", "")
    const colorGradient = get(data, "cardComponent.fields.colorGradient", "")
    
    const label2 = get(data, "secondaryCta.fields.button", "")
    const url2 = get(data, "secondaryCta.fields.cta_url", "")
    return (
        <Row className={`page-hero-wrapper ${colorGradient}`}>
            <Row className={`page-hero-row-${shapeColor}`} style={{backgroundImage: `url(${backgroundImg})`}}>
                <Col sm={10} md={8} lg={7} className={"page-hero-content-container page-hero-content"}>
                    <HeroContent containerClass="z-2" title={header} bodyText={bodyText} url={url} label={label} url2={url2} label2={label2}/>
                    {/* {isMobile && banner && (
                        <div className="badge-container">
                            <div className="z-2 flex-column d-flex justify-content-end text-right">
                                <span className="text-right">“I took the live review and was blown away.</span>
                                {starCount && (
                                    <span className="d-flex d-flex justify-content-end  my-2">
                                        {Array.from(Array(starCount)).map(() => {
                                            return <Image src={images.star} />;
                                        })}
                                    </span>
                                )}
                                <span className="text-right">Eric Lange, RN, South College.”</span>
                            </div>
                        </div>
                    )} */}
                </Col>
                {!isMobile && banner && <Col>banner</Col>}
            </Row>
        </Row>
    );
};
