import React from "react";
import Container from "react-bootstrap/Container";

export function FourHundredFour() {

    return (
        <Container className="p-0 overflow-hidden" fluid>
            <div className="d-flex flex-column align-items-center" style={{backgroundImage: "linear-gradient(360deg, #cdd8ef, #FFFFFF)", height: "1000px", textAlign: "center"}}>
                    <h1 style={{fontSize: "180px", margin: "1rem"}}>404</h1>
                    <h3 style={{fontSize: "40px", margin: "1rem" }}>The source page was not found!</h3>
                    <a style={{color: "#e85c0f", textDecoration: "none"}}href="https://www.hurstreview.com/">Click here to go back to Hurst Review's website!</a>
            </div>
        </Container>
    );
}

