import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "components";
import { useBlog } from "hooks/community/useBlog";
import { useCategoryFilter } from "hooks/community/useCategoryFilter";
import { BlogPreview } from "components/TestimonialBanner";
import { FilterBlogs } from "components/FilterBlogs";
import { getDataByField } from "@services/contentfulClient";
import { contentFulIds } from "@services/contentfulIds";
import { useSEO } from "@hooks/useSEO";
import { SeoTags } from "@components/index";
import ReactPaginate from "react-paginate";
import "@styles/Paginator.css";

export async function getBlogFilterData(selectedBlog) {
    return await getDataByField({
        content_type: "post",
        'fields.blogsuccessCategory.sys.contentType.sys.id': 'blogsuccessCategory',
        "fields.blogsuccessCategory.fields.blogsuccessStory": selectedBlog,
        include: 10,
        order: "fields.publishingDate",
    });
}

export async function getAllBlogs() {
    return await getDataByField({
        'fields.blogsuccessCategory.sys.contentType.sys.id': 'blogsuccessCategory',
        include: 10,
    });
}

export function Blog() {
    // CommonJS
    const [selectedBlog, setSelectedBlog] = useState('');
    const [data, setData] = useState({});
    const seoData = useSEO(contentFulIds.REACT_APP_COMMUNITY_BLOG_PAGE_SEO_ID);
    const [pageNumber, setPageNumber] = useState(0);
    const objsPerPage = 6;
    const pagesVisited = pageNumber * objsPerPage;

    const dataHero = useBlog();
    const dataCategory = useCategoryFilter();

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const waveClasses = {
        home: "home-wave-2",
        nclex: `nclex-wave-1`
    }

    useEffect(() => {
        pageView(window.location.href);
    }, []);

    useEffect(() => {
        (async () => {
            let blogData = await getBlogFilterData(selectedBlog);
            setData(blogData)
        })()     
    }, [selectedBlog]);

    if (!data || !seoData) {
        return null;
    }
    

    let mountedArr = {
        "postArray": [],
        "categories": []
    };

    if(dataCategory) {
        mountedArr.categories = dataCategory;
    }

    if (!data && !dataHero && data !== {}) {
        return null;
    }

    function sortPostsByDate(a, b) {
        return new Date(a.sys.createdAt).getTime() - new Date(b.sys.createdAt).getTime();
    }

    let unnorderedOnlyBlogData = data.items;
    
    let displayObjs = undefined
    let pageCount = 0;

    if(unnorderedOnlyBlogData) {
        // console.log(">>unnorderedOnlyBlogData", unnorderedOnlyBlogData)
        let onlyBlogData = unnorderedOnlyBlogData.sort(sortPostsByDate).reverse()
        function getValues() {

            Object.keys(onlyBlogData).map((key, index) => {
                let postObject = {}
                postObject = {
                    "post": onlyBlogData[index]['fields'],
                    "sysId": onlyBlogData[index]['sys']['id']
                }
                        
                mountedArr.postArray.push(postObject);

                return mountedArr;
            })
            return mountedArr;       
        }

        // console.log(">>getValues(): ", getValues())
        let postsPaginated = getValues().postArray.slice(0, 50);

        displayObjs = postsPaginated
        .slice(pagesVisited, pagesVisited + objsPerPage)
        .map((obj) => {
            if(obj !== undefined) {
                return (
                    <BlogPreview data={obj} />
                );
            } else{
                return <span/>
            }
        });

        pageCount = Math.ceil(postsPaginated.length / objsPerPage);
    }

    
    let heroData = {};

    if(dataHero) {
        heroData = {
            cardComponent: {
                "fields": dataHero.includes.Entry[0]['fields']
            }
        }
    }
    
    return (
        <Container className="p-0 blog-container" fluid>
            <SeoTags data={seoData} />
            <PageHero data={heroData} />
            <div className={waveClasses["nclex"] + " blog-wave-1"}></div>
            
            <FilterBlogs
                selectedBlog={selectedBlog}
                data={mountedArr.categories}
                setSelectedBlog={setSelectedBlog}
             />
            {displayObjs ? displayObjs : '<span/>'}

            <div className={waveClasses["nclex"] + " blog-wave-2"} ></div>

            <ReactPaginate
                previousLabel={"<"}
                pageClassName="page-item-none"
                breakClassName="page-item-none"
                nextLabel={">"}
                pageCount={pageCount}
                pageRangeDisplayed={1}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
            />
            
        </Container>
    );
}
