import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Card } from "react-bootstrap";
import defaultBannerImage from "@assets/BlogCardImages/blogcard.png";
import format from 'date-fns/format';
import ReactMarkdown from "react-markdown";
//const parse = require("html-react-parser");
export const TestimonialBanner = ({data}) => {

    // If an image exists, use it - else use a default
    const testimonialImage = data.fields.testimonialImage ? data.fields.testimonialImage.fields.file.url : defaultBannerImage;

    return (
        <Card className="testimonial m-5">
            <Row className={`col-12 g-0`}>
                <Col md={3}>
                    <Image className="testimonial-img" src={testimonialImage} />
                </Col>
                <Col md={9} className="testimonial-text">
                    <Card.Body>
                            {<p>{data.fields.testimonialDescription}</p>}
                            {<p><strong>{data.fields.contributorName}</strong></p>}
                 
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};


export const BlogPreview = ({data}) => {
    if(data !== undefined && data.post !== undefined) {


        let blogPreviewText = data.post.bodyText.slice(0,270);

        let parsedMarkdown = <ReactMarkdown className={"blog-preview-text"}>{blogPreviewText}</ReactMarkdown>;

        // If an image exists, use it - else use a default
        const blogImage = data.post.image?.fields.file.url ? data.post.image.fields.file.url : defaultBannerImage;

        return (
            <Card className="blog-preview m-5">
                <Row className={`col-12 g-0`}>
                    <Col md={3}>
                        <Image className="testimonial-img" src={blogImage} alt="Girl on Blog Page"/>
                    </Col>
                    <Col md={9} className="testimonial-text">
                        <Row col={12}>
                            <Col>
                                <h1>{data.post.header !== undefined ? data.post.header : '' }</h1>
                            </Col>
                            <Card.Body>
                                {parsedMarkdown}
                                {<p className={"blog-preview-date"}><strong>{data.post.publishingDate !== undefined ? format(new Date(data.post.publishingDate), 'LLLL dd') : ''}</strong></p>}
                                {<p className={"blog-preview-author"}><strong>{data.post.authorName !== undefined ? data.post.authorName : ''}</strong></p>}
                            </Card.Body>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}></Col>
                    <Col md={9} className="read-more"><p><a href={`/community/blog/detail/${data.post.blogUrl}`}>READ MORE</a></p></Col>
                </Row>
            </Card>
        );
    } else {
        return ("</>");
    }
}