import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useHealthcare } from "@hooks/healthcare/useHealthcare";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import {
    LandingHero,
    TripleCards,
    ProductCarousel,
    SeoTags,
} from "@components";
import { TestimonialCarousel } from "components/TestimonialCarousel";


export function Healthcare() {
    
    // CommonJS
    const data = useHealthcare();
    const seoData = useSEO(contentFulIds.REACT_APP_HEALTHCARE_PAGE_SEO_ID);
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    if (!data || !seoData) {
        return null;
    }
    
    return (
        <Container className="p-0 overflow-hidden healthcare" fluid>
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} />
            <TripleCards waveType="nclex" extraWaveClass="landing-hero-wave healthcare-wave" data={data.fourCardsTextOutside} />
            <div className="top-spacer"/>
            <ProductCarousel data={data.slider} extraWaveClass={"healthcare-wave-2"} />
            <div className="top-spacer"/>
            <TestimonialCarousel data={data.testimonialCarousel} />
        </Container>
    );
}
