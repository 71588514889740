import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";

import { CardWithButton } from "components/Card";
import { useBreakPoint } from "hooks/useBreakPoint";
import { parseText } from "@utils/parse";
import { useMediaQuery } from "react-responsive";

export const InfoCarousel = ({ data, buttonClass, cardClass, extraWaveClass = "" }) => {
    const { smallScreen } = useBreakPoint();
    const subTitleBreakpoint = useMediaQuery({
        query: "(max-width: 1360px)",
    });
    const carouselWidth = subTitleBreakpoint ? "w-90" : "w-75";

    if (!data) {
        return null;
    }
    const cardMap = Object.values(data);
    const result = cardMap
        .filter((item) => typeof item !== "string")
        .map((item) => ({ ...item.fields, cta: item.fields.cta.fields }));


    const waveClass = `home-wave-1 ${extraWaveClass}`;
    
    if (smallScreen) {
        return (
            <>
                <div className="home-wave-3"></div>
                <Row className="m-1 info-carousel-row">
                    <Col className="text-center" md={5}>
                        <h1>{data.header}</h1>
                    </Col>
                    <Carousel>
                        {result &&
                            result.map((item) => {
                                const content = item.bodyText.split(";");
                                return (
                                    <Carousel.Item key={item.header}>
                                        <CardWithButton
                                            buttonClass={buttonClass}
                                            cardClass={`info-carousel-card ${cardClass}`}
                                            titleClass="info-title-color w-80"
                                            subtitleClass="info-subtitle font-family-secondary"
                                            cardTextClass="lh-md w-80 font-20"
                                            title={item.header.split('_')}
                                            subtitle={content[0]}
                                            content={parseText(content[1])}
                                            label={item.cta.ctaName}
                                            url={item.cta.cta_url}
                                        />
                                    </Carousel.Item>
                                );
                            })}
                    </Carousel>
                </Row>
            </>
        );
    }
    return (
        <>
            <div className={waveClass}></div>
            <Row className={`info-carousel-row mx-auto ${carouselWidth}`}>
                <Row className="text-center mb-5">
                    <h1>{data.header}</h1>
                </Row>
                {result &&
                    result.map((item) => {
                        const content = item.bodyText.split(";");
                        
                        return (
                            <Col key={item.header}>
                                <CardWithButton
                                    buttonClass={buttonClass}
                                    cardClass={"info-carousel-card"}
                                    titleClass="info-title-color w-80 mb-0"
                                    subtitleClass="info-subtitle font-family-secondary"
                                    cardTextClass="lh-md w-100 font-20"
                                    title={item.header.split('_')}
                                    subtitle={subTitleBreakpoint ? content[0].split(' ') : content[0]}
                                    content={parseText(content[1])}
                                    label={item.cta.ctaName}
                                    url={item.cta.cta_url}
                                />
                            </Col>
                        );
                    })}
            </Row>
        </>
    );
};
