import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useBreakPoint } from "@hooks/useBreakPoint";
import { HeroContent } from "./HeroContent";
import { get } from "lodash";
import { parseText } from "@utils/parse";


export const LandingHero = ({ data  }) => {
    const bodyText = get(data, "cardComponent.fields.bodyText", "");
    const cardHeader = get(data, "cardComponent.fields.header", "");
    const imageUrl = get(data, "cardComponent.fields.image.fields.file.url", "/");
    const buttonUrl = get(data, "cardComponent.fields.cta.fields.cta_url", null);
    const buttonLabel = get(data, "cardComponent.fields.cta.fields.button", null);
    const altImage = get(data, "cardComponent.fields.image.fields.description", "Image");
    const { break768 } = useBreakPoint();
    return (
        <Row className="landing-hero-row">
            <Col md={7}>
                <div className={"page-hero-content-container position-relative"}>
                    <HeroContent
                        type="landing"
                        containerClass="landing-hero-container"
                        title={data.title}
                        bodyText={parseText(bodyText)}
                        subheadline={cardHeader}
                        url={buttonUrl}
                        label={buttonLabel}
                        shapeColor={data.cardComponent.fields.colorGradient !== undefined ? data.cardComponent.fields.colorGradient : 'purple'}
                    />
                </div>
                {break768 && (
                    <div className={"hero-image"}>
                        <Image className="d-block w-100" src={imageUrl} alt={altImage}/>
                    </div>
                )}
            </Col>
            {!break768 && (
                <Col className={"hero-image-col"}>
                    <div className={"hero-image"}>
                        <Image className="d-block w-100 hero-header-adjustment" src={imageUrl} alt={altImage}/>
                    </div>
                </Col>
            )}
        </Row>
    );
};
