import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { BigCardWithOverlay, PageHero } from "components";
import {
    RowPillarImageRight
} from "@components/RowPillarImageRight";
import { useCareers } from "hooks/about/useCareers";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { SeoTags } from "@components/index";

export function Careers() {
    // CommonJS
    const data = useCareers();
    let seoData = useSEO(contentFulIds.REACT_APP_CAREERS_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden" fluid>
           <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <div className="nclex-wave-1 about-careers-wave"></div>
            <BigCardWithOverlay data={data.banner} />
            <RowPillarImageRight data={data.rowPillarLeft} imgContainerClass="nclex-bigbanner-img-container"/>
            <div className="nclex-wave-2 careers-wave" style={{height: "100%"}}></div>
        </Container>
    );
}
