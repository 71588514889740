import { useMediaQuery } from "react-responsive";

export const useBreakPoint = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 576px)",
    });
    const smallScreen = useMediaQuery({
        query: "(max-width: 960px)",
    });
    const break768 = useMediaQuery({
        query: "(max-width: 768px)",
    });
    return { isMobile, smallScreen, break768 };
};
