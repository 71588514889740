import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { DoubleCards, TripleCards, BigBanner, PageHero } from "@components/index";
import { useTotalPass } from "hooks/nclexreview/useTotalPass";
import { SeoTags } from "@components/index";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";

export function TotalPass() {
    // CommonJS

    const data = useTotalPass();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_TOTALPASS_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }


    return (
        <Container className="p-0 overflow-hidden page-schools-subpage page-total-pass" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside} />
            <BigBanner
                extradata={data.rowPillarLeft}
                imgContainerClass="nclex-bigbanner-img-container"
                reverse
                waveType="nclex"
                data={data.heroBadge}
            />
            <DoubleCards type="carousel" extraWaveClass="nclex-wave-generic-2" waveType="nclex" rowClass={"nclex-double-card-row"} data={data.tripleCards} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric" data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
