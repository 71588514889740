import { useEffect, useState } from "react";
import { getDataByField } from "@services/contentfulClient";

export const useCategoryFilter = () => {
    const [categories, setCategories] = useState();
    
    useEffect(() => {
            (async () => {
                const response = await getDataByField({
                    content_type: "blogsuccessCategory",
                    include: 10,
                });
                const data = response.items;
                if (data) {
                    setCategories(data);
                }
            })();
    }, []);

    return categories;
};
