import { useEffect, useState } from "react";
import { getDataById } from "@services/contentfulClient";
import { contentFulIds } from "@services/contentfulIds";

export const useBlog = () => {
    const [homeData, setHomeData] = useState();
    useEffect(() => {
        (async () => {
            const data = await getDataById(contentFulIds.REACT_APP_COMMUNITY_BLOG_HERO_ID);
            
            setHomeData(data);
        })();
    }, []);
    return homeData;
};
