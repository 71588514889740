import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { useNCLEXReview } from "@hooks/nclexreview/useNCLEXReview";
import {
    HorizontalBanner,
    LandingHero,
    ProductCarousel,
    SimpleCardWithButton,
    SeoTags,
} from "@components/index";
import { TestimonialCarousel } from "components/TestimonialCarousel";

export function NCLEXReview() {
    // CommonJS
    const data = useNCLEXReview();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_PAGE_SEO_ID);
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }


    return (
        <Container className="p-0 overflow-hidden" fluid>
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} />
            <ProductCarousel data={data.slider} extraWaveClass="landing-hero-wave nclex-review-wave" />
            {data.banner && data.banner.bannerName === "Banner - The Ultimate NCLEX Review 1" ? (
                <HorizontalBanner buttonColClass="nclex-horizontal-button-col d-flex my-3" rowClass="nclex-horizontal-banner-row" data={data.banner} />
                ) : (
                    <span/>
            )}
            

            {data.banner && data.banner1 ? (
                <SimpleCardWithButton cardClass="w-80" rowClass={"d-flex justify-content-center align-items-center nclex-simple-card-button-row my-5"} data={data.banner1} waveStyle={{marginTop: "unset"}}/>
                ) : (
                    <span/>
            )}

            {(data.banner && data.banner.bannerName === "Banner - The Ultimate NCLEX Review 2") ? (
                <SimpleCardWithButton cardClass="w-80" rowClass={"d-flex justify-content-center align-items-center nclex-simple-card-button-row my-5"} data={data.banner} />
                ) : (
                    <span/>
            )}
            <TestimonialCarousel data={data.testimonialCarousel} />
        </Container>
    );
}
