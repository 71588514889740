import React from "react";
import { Helmet } from "react-helmet";

export const SeoTags = ({ data }) => {
    if (!data) {
        return null;
    }

    let ogImage = data.fields.ogImage !== undefined ? data.fields.ogImage.fields.file.url : ''
    let seoMetadata = {
        //htmlTitle: data.fields.htmlTitle === undefined ? data.fields.htmlTitle  : data.pageTitle,
        metaDescription: data.fields.metaDescription !== undefined ? data.fields.metaDescription : '',
        htmlTitle: data.fields.htmlTitle === undefined ? data.pageTitle: data.fields.htmlTitle,
        canonicalTag: String(data.fields.canonicalTag !== undefined ? data.fields.canonicalTag : document.location).split('?')[0],
        metaRobotsTag: data.fields.metaRobotsTag !== undefined ? data.fields.metaRobotsTag : 'index,follow',
        ogImage: 'https:' + ogImage,
        imageAltTags: data.fields.imageAltTags !== undefined ? data.fields.imageAltTags : ''
    }

    return (
        <Helmet>
            <title>{seoMetadata.htmlTitle}</title>
            <meta name="description" content={seoMetadata.metaDescription} />
            <link rel="canonical" href={seoMetadata.canonicalTag} />
            <meta name="robots" content={seoMetadata.metaRobotsTag} />
            <meta name="og:title" content={seoMetadata.htmlTitle} />
            <meta name="og:description" content={seoMetadata.metaDescription} />
            <meta name="og:image" content={seoMetadata.ogImage} />
        </Helmet>
        
    );
};
