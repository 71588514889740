import * as contentful from "contentful";

export const contentfulClient = contentful.createClient({
    environment: "master",
    space: "nkieeu5q11ki",
    accessToken: "7booHdtSd1mUA8s6oL1UxjwiNhDNK4w4JkSAoYCVx6g"
});

export const getDataById = async (id) => {
    return contentfulClient.getEntries({ "sys.id": id, include: 10 });
}

export const getDataByField = async (options) => {
    return contentfulClient.getEntries(options);
}