import React from "react";
import Carousel from "react-multi-carousel";
import Row from "react-bootstrap/Row";
import "react-multi-carousel/lib/styles.css";
import { ButtonComponent as Button } from "@components/Button";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

export const FilterBlogs = (props) => {

    const filterItems = [...new Set(props.data.map(item => item.fields.blogsuccessStory))];
    const onClickCategory = item => {
       props.setSelectedBlog(item);
    };

    return (
        <Row className="m-3 filter-carousel-row">
            <Carousel swipeable={true} responsive={responsive}>
                {filterItems.map((item, index) => {
                    return (
                        <div onClick={() => onClickCategory(item)}>
                            <Button
                                active={props.selectedBlog === item}
                                containerClass={`horizontal-filter-button ${props.selectedBlog === item ? "" : "" }`}
                                key={item}
                                selectedElement={props.selectedBlog}
                                label={item}
                                hasOnclick='block'
                                hasUrl='block'
                                color={props.selectedBlog === item ? "green" : "purple"}
                            />
                        </div>
                    );
                })}
            </Carousel>
        </Row>
    );
};
