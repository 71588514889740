import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useState } from "react";
import { marked } from "marked";
import { ButtonComponent as Button } from "@components/Button";

export const FaqBanner = ({ data, waveType = "home" }) => {
    const [selected, setSelected] = useState(-1);
    if(!data) {
        return null
    }
    const faq = data.faqComponent.map(item => item.fields);

    const onClick = (index) => {
        if (index !== selected) {
            setSelected(index);
        } else {
            setSelected(-1);
        }
    };
    return (
        <>
            {waveType === "home" ? <div className="home-wave-4"></div> : <div className="home-wave-3"></div>}
            <div className="home-wave-5"></div>

            <Row className="faq-banner-row d-flex justify-content-center align-items-center m-4">
                <Col className="text-left" md={8}>
                    <h1>FAQs</h1>
                </Col>
                <Card className="p-5 faq-banner-container-card" >
                    {faq &&
                        faq.map((item, index) => {
                            return (
                                <div key={item.header + index}>
                                    <Card className="faq-card p-5 my-3" onClick={() => onClick(index)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Card.Title className="faq-card-title">{item.header}</Card.Title>
                                            <Image
                                                className="faq-arrow"
                                                src={
                                                    selected === index
                                                        ? require("@assets/mock/faq_arrow_down.png")
                                                        : require("@assets/mock/faq_arrow_left.png")
                                                }
                                                alt="FAQ Arrow Down"
                                            />
                                        </div>
                                    </Card>
                                    <Card.Text
                                        className={`faq-hidden-text mx-4 ${
                                            selected === index ? "faq-h-100" : "faq-h-0"
                                        }`}
                                        dangerouslySetInnerHTML={{__html: marked.parse(item.bodyText)}} 
                                    >
                                    </Card.Text>
                                </div>
                            );
                        })}
                </Card>
                <div style={{backgroundColor: "#f7f9fc",marginBottom:"25px", height:"95px"}}>
                    <center>
                        {data.cta !== undefined ? <Button color={data.cta.buttonGradient} url={data.cta.cta_url} label={data.cta.button} /> : ''}
                    </center>
                </div>
                        
                
            </Row>
        </>
    );
};
