export function ButtonComponent({
    label,
    url,
    containerClass = "",
    color = "orange",
    colorOverride = false,
    hasUrl = "block",
    hasOnclick = "none",
    active = false,
    selectedElement = "none",
    id = null
}) {

    let isRelativePath = true;
    if((url) && (url.includes("http") || url.includes("https"))) {
        isRelativePath = false;
    }

    return (
        <div id="mainDivButton"
            className={`custom-button ${containerClass}`}>
            {isRelativePath ? (
                <a className={`text-decoration-none ${color}`}
                   href={url || "#"}
                   {...(id ? {id} : {})}
                   style={{ display: `${hasUrl}` }}
                >
                    <span style={{"display": "block"}}>{label}</span>
                </a>
            ) : (
                <a className={`text-decoration-none ${color}`}
                   target="_blank" rel="noreferrer"
                   href={url || ""}
                   {...(id ? {id} : {})}
                   style={{ display: `${hasUrl}` }}
                >
                    <span style={{"display": "block"}}>{label}</span>
                </a>
            )}
        </div>
    );
}
