import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "@components";
import { useAmbassadorDetail } from "hooks/community/useAmbassadorDetail";
import { AmbassadorDetailCard } from "components/AmbassadorDetailCard";


export function AmbassadorDetail(ambassador = 1) {
    // CommonJS
    const data = useAmbassadorDetail(ambassador);
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-ambassadors page-ambassadors-detail" fluid>
            <PageHero shapeColor={"orange"} data={ {cardComponent: {fields: {header: "Ambassadors"}}}} />
            <div className="nclex-wave-1 wave-detail"></div>
            <AmbassadorDetailCard data={data} />
        </Container>
    );
}
