// Styles
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "@styles/themes/default";
// Routes
import { Router } from "@navigation/Router";

export function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Router />
        </ThemeProvider>
    );
}
