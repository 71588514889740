import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { PageHero } from "@components";
import { useTeam } from "hooks/about/useTeam";
import { LeadershipTeamDetail } from "@components/LeadershipTeamDetail";


export function TeamDetailPage() {
    // CommonJS
    const data = useTeam();
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-team" fluid>
            <PageHero data={data.hero} />
            <div className="nclex-wave-ambassadors wave-detail"></div>
            <LeadershipTeamDetail data={data.leadershipTeamGallery} />
        </Container>
    );
}
