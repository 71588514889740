import React from "react";
import Image from "react-bootstrap/Image";
import { get } from "lodash";
import { useMediaQuery } from "react-responsive";


export const SocialMediaBanner = ({ data }) => {
    const mediumScreen = useMediaQuery({
        query: "(max-width: 1200px)",
    });
    


    const components = get(data, "socialMediaComponent", null);
    const inlineStyle = mediumScreen ? {width: '60px', margin: "2rem"} : {width: '100px', margin: "3rem"};
    
    if (!data) {
        return null
    }


    return(
        <div className={`d-flex flex-row flex-wrap justify-content-center mx-auto w-100 social-media-banner-row show-content`}>
            {components.map((component, key) => {
                const imageUrl = get(component, "fields.sociaMediaLogo.fields.file.url", null);
                const link = get(component, "fields.ctaSocialMediaLink.fields.cta_url", null);
                const altImage = get(component, "fields.sociaMediaLogo.fields.description", null);

                return (
                    <a className="d-flex align-items-center show-content" style={inlineStyle} href={link} key={key}>
                        <Image
                            className="w-100"
                            src={imageUrl}
                            alt={altImage}
                        />
                    </a>
                );
            
            })}
        </div>
        
    )
}