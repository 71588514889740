import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { BlogCard } from "components";
import { Image } from "react-bootstrap";
import { ButtonComponent } from "components/Button";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import defaultBlogdetailImage from "@assets/blogdetailImage.png";
import format from 'date-fns/format';
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
//const parse = require("html-react-parser");

import { FourHundredFour } from "pages/FourHundredFour";
import { getDataByField } from "@services/contentfulClient";
const BlogHeader = ({header, bannerImg, isHeaderGray}) => {
    return (
        <div className="blog-header">
            <h1 className={ (isHeaderGray && "blog-header-gray") }>{header}</h1>
            
            <Image src={bannerImg} alt="Blog Hero Image" />
        </div>
    )
}
const BlogBody = ({bodyText, postDate, postAuthor}) => {
    return (
        <BlogCard
            cardClass="vertical-banner-card blog-body"
            titleClass="align-self-start"
            cardBodyClass="lh-sm w-100 p-5"
            title=""
        >
            <div>
            </div>
            <div className="d-flex flex-column float-right w-100">
                <ReactMarkdown>{bodyText}</ReactMarkdown>
                {<p><strong>{postDate !== undefined ? format(new Date(postDate), 'LLLL dd') : ''}</strong></p>}
                {<p><strong>{postAuthor}</strong></p>}
            </div>
        </BlogCard>
    )
}
export function BlogDetail() {
    const params = useParams();
    const [blogData, setBlogData] = useState();
    const [loading, setLoading] = useState(true);
    const breakpoint1024 = useMediaQuery({
        query: "(min-width: 768px) and (max-width: 1024px)",
    })
    
    useEffect(() => {
        const fetchBlogDetail = async () => {
     
             const data = await getDataByField({
                 content_type: "post",
                 "fields.blogUrl": params.slug,
             });

             if (data.items.length === 0) return;

             setBlogData(data.items[0]);
             setLoading(false);
        }
 
        fetchBlogDetail().catch(console.error).finally(() => setLoading(false));
     }, [params.slug]);
    
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    
    if ((!blogData || blogData.fields === undefined) && !loading) {
        return <FourHundredFour />;
    }
    if (blogData && blogData.fields !== undefined && !loading) {
        let bannerImg = blogData.fields.image?.fields.file ? blogData.fields.image.fields.file.url : defaultBlogdetailImage;
        return (
            <Container className="p-0 overflow-hidden gradient-background" fluid>
                <Helmet>``
                    <title>{blogData.fields.htmlTitle}</title>
                    <meta name="description" content={blogData.fields.metaDescription} />
                    <link rel="canonical" href={blogData.fields.canonicalTag} />
                    <meta name="robots" content={blogData.fields.metaRobotsTag} />
                    <meta name="og:description" content={blogData.fields.metaDescription} />
                </Helmet>
                
                    <BlogHeader header={blogData.fields.postName} bannerImg={bannerImg} isHeaderGray={blogData.fields.isHeaderGray}/>
                    {breakpoint1024 && <br />}
                    <ButtonComponent
                        label="Return"
                        url="/community/blog"
                        containerClass="blog-button"
                        />
                    <BlogBody bodyText={blogData.fields.bodyText} postDate={blogData.fields.publishingDate} postAuthor={blogData.fields.authorName}/>
            </Container>
        );
    }
}
