import styled from "styled-components";

export const ContactOverride = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;

    main > h1 {
        text-align: center;
        margin-bottom: 5rem;
        z-index: 10;
    }

    .container-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 4rem;
        max-width: 40%;
        z-index: 10;
        flex-wrap: wrap;
    }

    .container-right {
        z-index: 10;
        flex-wrap: wrap;
    }
    
    #contacts-container {
        display: flex;
        width: 100%;
        justify-content: center;
        z-index: 10;
        flex-wrap: wrap;
    }
    #address-box {
        display: flex;
        padding: 20px;
        margin: 20px;
        z-index: 10;
    }
    #address-box-image {
        margin: auto;
        z-index: 10;
    }
    #address-box-text {
        padding: 20px;
        margin: auto;
        z-index: 10;
    }
    #phone-box {
        padding: 20px;
        margin: 20px;
        display: flex;
        z-index: 10;
    }
    #phone-box-image {
        margin: auto;
        z-index: 10;
    }
    #phone-box-text {
        padding: 20px;
        margin: auto;
        z-index: 10;
    }
    #schedule-box {
        padding: 20px;
        margin: 20px;
        display: flex;
        z-index: 10;
    }
    #schedule-box-image {
        margin: auto;
        z-index: 10;
    }
    #schedule-box-text {
        padding: 20px;
        margin: auto;
        z-index: 10;
    }

    .imageContainerContact {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin: 100px 150px;
            z-index: 10;
    
        .imageContact {
            padding: 20px;
            border-radius: 7%;
            background-color: ${(props) => props.theme["white"]};
            box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.2);
            z-index: 10;
        }
        .select_wrapper {
        }
    }
    .contactDataContainer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 20px 180px;
        margin-top: -65px;
        z-index: 10;

        .below {
            width: 350px;
        }
    }

    .contactIconsContainer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 20px 180px;
        z-index: 10;

        .below {
            width: 350px;
        }
    }


    .contact_info_name_wrapper {
        display: flex;
        z-index: 10;

        .contact_info_name {
            margin-left: 250px;
            padding: 20px;
            z-index: 10;
        }
    }

    .contact_info_phone_wrapper {
        display: flex;
        width: 50px;
        z-index: 10;

        .contact-phone-image {
            margin: auto;
            width: 50px;
            z-index: 10;
        }
        .contact-phone-text {
            padding-left: 40px;
            margin: auto;
            z-index: 10;
        }
    }

    .contact_info_email_wrapper {
        display: flex;
        width: 50px;
        z-index: 10;

        .contact-email-image {
            margin: auto;
            width: 50px;
            z-index: 10;
        }
        .contact-email-text {
            padding-left: 40px;
            margin: auto;
            z-index: 10;
        }
    }

    .rep_name_font {
        font-weight: bold;
        font-size: 1.2em;
        z-index: 10;
    }

    @media only screen and (max-width: 600px) {
        .container-left {
            width: 100%;
            flex: 100%;
            max-width: 100%;
            margin: 0px;
            line-height: 20px;
        }
        .container-right {
            width: 100%;
            flex: 100%;
            padding: 2px;
        }
        #address-box {
            padding-left: 40px;
            margin: 0px;
        }
        #phone-box {
            padding-left: 40px;
            margin: 0px;
        }
        #schedule-box {
            padding-left: 40px;
            margin: 0px;
            padding-bottom: 40px;
        }
        .top-spacer {
            margin-top: 0%;
        }
        .center-form-button {
            margin-left: 120px;
        }
    }
    

    // main:first-child {
    //     button {
    //         margin: 0 auto;
    //     }
    // }
    
    // main:last-child {
    //     section h1 {
    //         text-align: center;
    //     }
    // }
`;

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;

    .standardInput {
        border: 1px solid;
        border-color: #AAAFB0;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 20px;
        width: 100%;
        height: 90px;
        z-index: 10;
    }

    textarea {
        border: 1px solid;
        border-color: #AAAFB0;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 20px;
        width: 100%;
        height: 230px;
        z-index: 10;
    }

    label {
        font-weight: bold;
        color: #707070;
        font-family: 'Montserrat';
        padding-left: 1rem;
        z-index: 10;
    }


    button {
        border: 0;
        font-weight: 300;
        padding: .6rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        border: 1px solid transparent;
        color: ${(props) => props.theme["black500"]};
        box-shadow: 0px 5px 20px -3px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme["white"]};
        transition: .3s;
        margin: 1rem 0 0 0 !important;
        z-index: 10;

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            box-shadow: none;
            border-color: ${(props) => props.theme["black500"]};
        }
    }
  
  .contactInputField {
    width: 100%;
  }
`;