import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { LandingHero } from "@components";
import { useAbout } from "hooks/about/useAbout";
import Carousel from "react-multi-carousel";
import Row from "react-bootstrap/Row";
import {
    FaqBanner,
} from "@components/index";
import { SeoTags } from "@components/index";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";

export function About() {
    // CommonJS
    const data = useAbout();
    const seoData = useSEO(contentFulIds.REACT_APP_ABOUT_PAGE_SEO_ID);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }
    
    /*
    let pageTitle = seoData.fields.htmlTitle === undefined ? data.pageTitle : seoData.fields.htmlTitle
    let ogImage = seoData.fields.ogImage !== undefined ? seoData.fields.ogImage.fields.file.url : ''
    let seoMetadata = {
        htmlTitle: pageTitle,
        metaDescription: seoData.fields.metaDescription !== undefined ? seoData.fields.metaDescription : '',
        canonicalTag: seoData.fields.canonicalTag !== undefined ? seoData.fields.canonicalTag : document.location,
        metaRobotsTag: seoData.fields.metaRobotsTag !== undefined ? seoData.fields.metaRobotsTag : 'index,follow',
        ogImage: 'https:' + ogImage,
        imageAltTags: seoData.fields.imageAltTags !== undefined ? seoData.fields.imageAltTags : ''
    }*/
    
    data.dropdownFaq.cta = data.cta;

    return (
        <Container className="p-0 overflow-hidden page-about" fluid>
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} />
            <div className="nclex-wave-1 landing-hero-wave about-wave"></div>
            <Row className="m-3 faq-banner-row">
                <div style={{"width":"100%"}}><center><h1>{data.sliderHistory.header}</h1></center></div>
                <div className="top-spacer"/>
                <Carousel swipeable={true} responsive={responsive} gap="50" renderButtonGroupOutside={true}>
                    {data.sliderHistory.sliderComponent.map((item, index) => {
                        return (
                            <div className="about-card-body">
                                <span className="about-history-card-title">{item.fields.header}</span>
                                <br/>{item.fields.bodyText}
                            </div>
                        );
                    })}
                </Carousel>
            </Row>
            <FaqBanner data={data.dropdownFaq} />
        </Container>
    );
}
