import { useEffect, useState } from "react";
import { getDataById } from "@services/contentfulClient";
import { contentFulIds } from "@services/contentfulIds";

export const useStudents = type => {
    const [studentsData, setStudentsData] = useState();

    const ids = [
        {name: 'students', value: contentFulIds.REACT_APP_STUDENTS_PAGE_ID},
        {name: 'PN', value: contentFulIds.REACT_APP_STUDENTS_PN_PAGE_ID},
        {name: 'RN', value: contentFulIds.REACT_APP_STUDENTS_RN_PAGE_ID}
    ];

    const id = ids.find(item => item.name === type);
    useEffect(() => {
        (async () => {
            const data = await getDataById(id.value);
            const current = data.items[0];

            if (current) {
                const pageContent = current.fields.pageContent;
                let result = {};
                let count = 1;
                pageContent.forEach((item) => {
                    const fields = item.fields;

                    const homeKeys = Object.keys(fields).filter((item) => item.includes("Name"));
                    
                    if(!Object.keys(result).includes(homeKeys[0].replace("Name", ""))) {
                        result = Object.assign(result, {
                            [homeKeys[0].replace("Name", "")]: item.fields,
                        });
                    } else {
                        result = Object.assign(result, {
                            [homeKeys[0].replace("Name", "") + count]: item.fields,
                        });
                        count = count + 1;
                    }
                });

                setStudentsData(result);
            }
        })();
    }, [id.value]);
    return studentsData;
};