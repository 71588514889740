import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SimpleCard } from "components/Card";
import { parseText } from "@utils/parse";

export const InfoCard = ({ type, data, rowClass, colClass }) => {
    if(!data) {
        return null
    }
    return (
        <Row className={`m-1 info-card-row ${rowClass}`}>
            <Col className={colClass}>
                <SimpleCard cardClass="info-carousel-card info-card" content={parseText(data.bodyText)} />
            </Col>
        </Row>
    );
};
