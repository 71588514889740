// Routes
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "@layouts/DefaultLayout";
import { Helmet } from "react-helmet";

// Pages
import { Home } from "@pages/Home";
// import { NClex } from "@pages/NClex";
// import { NClexLive } from "pages/NClex-Live";
// import { NClexNStream } from "pages/NClex-Nstream";
// import { NClexNow } from "pages/NClex-Now";
// import { NClexAddOns } from "pages/NClex-AddOns";
// import { NClexNext } from "pages/NClex-Next";
// import { NClexTotalPass } from "pages/NClex-TotalPass";
import { Schools } from "@pages/Schools";
// import { SchoolsPartnership } from "@pages/Schools/Partnership";
import { SchoolsContinuingEducation } from "@pages/Schools/ContinuingEducation";
import { Testimonials } from "pages/Schools/Testimonials";
import { SchoolsContact } from "pages/Schools/Contact";
import { SchoolsOnlineClassroom } from "pages/Schools/OnlineClassroom";
import { Students } from "@pages/Students";
import { StudentsPN } from "@pages/Students/PN";
import { StudentsRN } from "@pages/Students/RN";
import { Healthcare } from "pages/Healthcare";
// import { Resources } from "@pages/Resources";
import { ResourcesFaq } from "pages/Resources/faq";
// import { ResourcesSh } from "pages/Resources/sh";
// import { ResourcesGlossary } from "pages/Resources/glossary";
import { FourHundredFour} from "@pages/FourHundredFour";

// NCLEXReview STACK
import { NCLEXReview } from '@pages/NCLEXReview';
import { AddOns } from '@pages/NCLEXReview/AddOns';
import { Live } from '@pages/NCLEXReview/Live';
import { Next } from '@pages/Schools/Next';
import { Now } from '@pages/NCLEXReview/Now';
// import { Plus } from '@pages/NCLEXReview/Plus';
import { NStream } from '@pages/NCLEXReview/NStream';
import { TotalPass } from '@pages/Schools/TotalPass';
import { Plus } from '@pages/NCLEXReview/Plus';
import { Elevate } from '@pages/NCLEXReview/Elevate';


import { Contact } from "pages/Contact";
import { About } from "@pages/About";
import { Team } from "@pages/About/Team";
import { Careers } from "@pages/About/Careers";
import { Guarantee } from "@pages/About/Guarantee";
import { Payment } from "@pages/About/Payment";

import { Community } from "@pages/Community";
import { Social } from "@pages/Community/Social";
import { Ambassadors } from "@pages/Community/Ambassadors";
import { Blog } from "@pages/Community/Blog";

import { TeamDetailPage } from "pages/About/Team/Detail";
import { BlogDetail } from "pages/Community/Blog/Detail";
import { AmbassadorDetail } from "pages/Community/Ambassadors/Detail";

// Students stack
// import { Students } from '@pages/Students';
// import { RN } from '@pages/Students/RN';
// import { PN } from '@pages/Students/PN';

// SCHOOLS STACK
// import { Schools } from '@pages/Schools';
// import { Contact } from '@pages/Schools/Contact';
// import { ContinuingEducation } from '@pages/Schools/ContinuingEducation';
// import { Partnership } from '@pages/Schools/Partnership';
// import { Testimonials } from '@pages/Schools/Testimonials';

// HEALTHCARE STACK
// Name changed to Healthcare per client request
// import { Businesses } from '@pages/Businesses';

// RESOURCES STACK
// import { Resources } from '@pages/Resources';
// import { FAQ } from '@pages/Resources/FAQ';
// import { Glossary } from '@pages/Resources/Glossary';
// import { SuccessStories } from '@pages/Resources/SuccessStories';

// COMMUNITY STACK
// import { Community } from '@pages/Community';
// import { Ambassadors } from '@pages/Community/Ambassadors';
// import { Blog } from '@pages/Community/Blog';
// import { Social } from '@pages/Community/Social';

// ABOUT STACK
// import { About } from '@pages/About';
// import { Careers } from '@pages/About/Careers';
// import { Guarantee } from '@pages/About/Guarantee';
// import { Team } from '@pages/About/Team';

// no stack
// import { ContactRepresentative } from '@pages/ContactRepresentative';
// import {FacultyApplication} from '@pages/FacultyApplication';
// import { Faculty } from '@pages/Faculty';
// import { BlogArticlesXYZ } from '@pages/BlogArticlesXYZ';
// import { SuccessStoriesXYZ } from '@pages/SuccessStoriesXYZ';

export function Router() {
    
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <script src="/popup.js" type="text/javascript"></script>
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<DefaultLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<FourHundredFour />} />

                        <Route path="/ultimate-nclex-review" element={<NCLEXReview />} />
                        <Route path="/ultimate-nclex-review/products/pre-ngn-prep" element={<AddOns />} />
                        <Route path="/ultimate-nclex-review/products/next" element={<Next />} />
                        <Route path="/ultimate-nclex-review/products/live" element={<Live />} />
                        <Route path="/ultimate-nclex-review/products/now" element={<Now />} />
                        <Route path="/ultimate-nclex-review/products/n-stream" element={<NStream />} />
                        <Route path="/ultimate-nclex-review/products/elevate" element={<Elevate />} />
                        
                        <Route path="/ultimate-nclex-review/products/plus" element={<Plus />}/>

                        <Route path="/schools" element={<Schools />} />
                        <Route path="/schools/continuing-education" element={<SchoolsContinuingEducation />} />
                        <Route path="/schools/contact" element={<SchoolsContact />} />
                        <Route path="/schools/testimonials" element={<Testimonials />} />
                        <Route path="/schools/total-pass" element={<TotalPass />} />
                        <Route path="/schools/next" element={<Next />} />
                        <Route path="/schools/online-classroom" element={<SchoolsOnlineClassroom />} />
                        
                        <Route path="/students" element={<Students />} />
                        <Route path="/students/pn" element={<StudentsPN />} />
                        <Route path="/students/rn" element={<StudentsRN />} />

                        <Route path="/healthcare" element={<Healthcare />} />
                        
                        <Route path="/faq" element={<ResourcesFaq />} />

                        <Route path="/about" element={<About />}/>
                        <Route path="/about/team" element={<Team />}/>
                        <Route path="/about/guarantee" element={<Guarantee />}/>
                        <Route path="/about/careers" element={<Careers />}/>
                        <Route path="/about/payment-plans" element={<Payment />}/>

                        
                        <Route path="/about/team/marlene-hurst" element={<TeamDetailPage />}/>
                        <Route path="/about/team/roberto-bonilla" element={<TeamDetailPage />}/>
                        <Route path="/about/team/keith-gardner" element={<TeamDetailPage />}/>
                        <Route path="/about/team/becky-daniels" element={<TeamDetailPage />}/>
                        <Route path="/about/team/adam-dear" element={<TeamDetailPage />}/>
                        <Route path="/about/team/danita-hobbs" element={<TeamDetailPage />}/>
                        <Route path="/about/team/ashley-griffin" element={<TeamDetailPage />}/>
                        <Route path="/about/team/clark-cummins" element={<TeamDetailPage />}/>
                        <Route path="/about/team/donna-davis" element={<TeamDetailPage />}/>

                        <Route path="/community" element={<Community />}/>
                        <Route path="/community/social" element={<Social />}/>
                        <Route path="/community/ambassadors" element={<Ambassadors />}/>
                        <Route path="/community/ambassadors/ambassador1" element={<AmbassadorDetail ambassador={1} />}/>
                        <Route path="/community/ambassadors/ambassador2" element={<AmbassadorDetail ambassador={2} />}/>
                        <Route path="/community/ambassadors/ambassador3" element={<AmbassadorDetail ambassador={3} />}/>
                        <Route path="/community/ambassadors/ambassador4" element={<AmbassadorDetail ambassador={4} />}/>
                        <Route path="/community/blog" element={<Blog />}/>
                        <Route path="/community/blog/detail/:slug" element={<BlogDetail />}/>
                        {/* <Route path="/community/blog/detail/undefined" element={<FourHundredFour />} /> */}

                        <Route path="/contact" element={<Contact />}/>

                        {/*
                    <Route path="/schools/partnership" element={<SchoolsPartnership />} />
                    <Route path="/schools/testimonials" element={<Testimonials />} />
                    <Route path="/resources" element={<Resources />} />
                    <Route path="/resources/success-stories" element={<ResourcesSh />} />
                    <Route path="/resources/glossary" element={<ResourcesGlossary />} />

                    <Route path="/ultimate-nclex-review/products/plus" element={<Plus />}/>
                    <Route path="/schools/testimonials" element={<Testimonials />}/>
                    {<Route path="/schools/contact" element={<ContactRepresentative />}/>}
                    <Route path="/resources/faq" element={<FAQ />}/>
                    <Route path="/resources/success-stories" element={<SuccessStories />}/>
                    <Route path="/resources/success-stories/success-stories-XYZ/:id" render={(props) => <SuccessStoriesXYZ {...props} />}/>
                    <Route path="/resources/glossary" element={<Glossary />}/>
                    
                    <Route path="/community/blog/articles_XYZ/:id" element={<BlogArticlesXYZ />}/>
                    
                    
                    <Route path="/careers" element={<Careers />}/>
                    <Route path="/about/careers/faculty" element={<Faculty />}/>
                    
                    <Route path="/careers/faculty-application/page1" element={<FacultyApplication />}/> */}
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}
