import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { LandingHero, RowPillar } from "@components/index";
import { useCommunity } from "hooks/community/useCommunity";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import { SeoTags } from "@components/index";
import { useBreakPoint } from "@hooks/useBreakPoint";

export function Community() {
    // CommonJS
    const { break768 } = useBreakPoint();
    const data = useCommunity();
    const seoData = useSEO(contentFulIds.REACT_APP_COMMUNITY_PAGE_SEO_ID);

    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }

    let RowPillarRight1button = {
        color: "green",
        url: data.rowPillarRight.cardComponent.fields.cta.fields.cta_url,
        title: data.rowPillarRight.cardComponent.fields.cta.fields.button,
    };
    data.rowPillarRight.button = RowPillarRight1button;

    let RowPillarLeft1button = {
        color: "purple",
        url: data.rowPillarLeft.cardComponent.fields.cta.fields.cta_url,
        title: data.rowPillarLeft.cardComponent.fields.cta.fields.button,
    };
    data.rowPillarLeft.button = RowPillarLeft1button;

    let RowPillarRight2button = {
        color: "orange",
        url: data.rowPillarRight1.cardComponent.fields.cta.fields.cta_url,
        title: data.rowPillarRight1.cardComponent.fields.cta.fields.button,
    };
    data.rowPillarRight1.button = RowPillarRight2button;

    let RowPillarLeft2button = {
        color: "green",
        url: data.rowPillarLeft2.cardComponent.fields.cta.fields.cta_url,
        title: data.rowPillarLeft2.cardComponent.fields.cta.fields.button,
    };
    data.rowPillarLeft2.button = RowPillarLeft2button;

    return (
        <Container className="p-0 overflow-hidden" fluid>
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} shapeColor="orange" />
            <div className="nclex-wave-1 landing-hero-wave community-wave-1"></div>
            {!break768 && <div className="home-wave-2 wave-reverse community-first-wave"></div>}

            <RowPillar data={data.rowPillarRight} type="quote" />
            <div className="home-wave-2 community-second-wave"></div>
            <RowPillar data={data.rowPillarLeft} type="quote" reverse="true" />
            <div className="home-wave-2 wave-reverse community-wave-small" style={{height: "100%"}}></div>
            <RowPillar data={data.rowPillarRight1} type="quote" />
            <div className="home-wave-2 community-wave-small" style={{height: "100%"}}></div>
            <RowPillar data={data.rowPillarLeft2} type="quote" reverse="true" />
        </Container>
    );
}
