import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";

import {
    DoubleCards,
    TripleCards,
    BigBanner,
    PageHero,
    SeoTags,
} from "@components/index";
import { useNext } from "hooks/nclexreview/useNext";

//const parse = require("html-react-parser");

export function Next() {
    // CommonJS

    const data = useNext();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_NEXT_PAGE_SEO_ID);
    useEffect(() => {
        pageView(window.location.href);
    }, []);

      
    if (!data || !seoData) {
        return null;
    }
    
    return (
        <Container className="p-0 overflow-hidden page-schools-subpage page-next" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside} />
            <BigBanner  extradata={data.rowPillarLeft} imgContainerClass="nclex-bigbanner-img-container" reverse data={data.heroBadge} />
        <DoubleCards type="carousel" extraWaveClass="nclex-wave-generic-2" waveType="nclex" rowClass={"nclex-double-card-row"} data={data.tripleCards} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric"  data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
