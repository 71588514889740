import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import Image from "react-bootstrap/Image";
import { useHome } from "@hooks/useHome";
import { useSEO } from "@hooks/useSEO";
import { useBreakPoint } from "hooks/useBreakPoint";
import { contentFulIds } from "@services/contentfulIds";
import {
    Hero,
    DoubleCards,
    HorizontalBanner,
    TripleCards,
    VerticalBanner,
    InfoCarousel,
    InfoCard,
    FaqBanner,
    BigBanner,
    SeoTags,
} from "@components/index";

export function Home() {
    // CommonJS
    const homeData = useHome();
    const seoData = useSEO(contentFulIds.REACT_APP_HOME_PAGE_SEO_ID);
    const { break768 } = useBreakPoint();
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!homeData || !seoData) {
        return null;
    }
    
    if (break768) {
        return (
            <Container className="p-0 overflow-hidden homepage" fluid>
                <SeoTags data={seoData} />
                <Hero data={homeData.heroCarousel} />
                <Container className="p-0 overflow-hidden gradient-container" fluid>
                    <DoubleCards data={homeData.doubleCards} />
                    <HorizontalBanner data={homeData.banner} />
                </Container>
                <Image className="wave mobile home-wave-new-2" src={require("@assets/updatedWaves/mobile/home_wave_2_opaque.png")}/>
                <Container className="p-0 overflow-hidden gradient-container" fluid>
                    <TripleCards data={homeData.tripleCardsTextOutside} centeredTitle={false}/>
                    <VerticalBanner data={homeData.banner1} />
                </Container>
                <Image className="wave mobile home-wave-new-3" src={require("@assets/updatedWaves/mobile/home_wave_3_opaque.png")}/>
                <Container className="p-0 overflow-hidden gradient-container" fluid>
                    <InfoCarousel cardClass="home-info-card-carousel" buttonClass="home-info-carousel-button" data={homeData.journeyCards} extraWaveClass="home-wave-3"/>
                    <InfoCard colClass="home-info-card-col" data={homeData.banner2} type="button" />
                    <BigBanner waveType={"home"} data={homeData.blogBlock} />
                </Container>
                <Image className="wave mobile home-wave-new-4" src={require("@assets/updatedWaves/mobile/home_wave_4_opaque.png")}/>
                <Container className="p-0 overflow-hidden gradient-container" fluid>
                    <FaqBanner data={homeData.dropdownFaq} />
                </Container>
            </Container>
        );
    }
    return (
        <Container className="p-0 overflow-hidden homepage" fluid>
            <SeoTags data={seoData} />
            <Hero data={homeData.heroCarousel} />
            <Container className="p-0 overflow-hidden gradient-container" fluid>
                <DoubleCards data={homeData.doubleCards} />
                <HorizontalBanner data={homeData.banner} />
            </Container>
            <Image className="wave desktop home-wave-new-2" src={require("@assets/updatedWaves/desktop/home_wave_2_opaque.png")}/>
            <Container className="p-0 overflow-hidden gradient-container" fluid>
                <TripleCards data={homeData.tripleCardsTextOutside} centeredTitle={false}/>
                <VerticalBanner data={homeData.banner1} />
            </Container>
            <Image className="wave desktop home-wave-new-3" src={require("@assets/updatedWaves/desktop/home_wave_3_opaque.png")}/>
            <Container className="p-0 overflow-hidden gradient-container" fluid>
                <InfoCarousel cardClass="home-info-card-carousel" buttonClass="home-info-carousel-button" data={homeData.journeyCards} extraWaveClass="home-wave-3"/>
                <InfoCard colClass="home-info-card-col" data={homeData.banner2} type="button" />
                <BigBanner waveType={"home"} data={homeData.blogBlock} />
            </Container>
            <Image className="wave desktop home-wave-new-4" src={require("@assets/updatedWaves/desktop/home_wave_4_opaque.png")}/>
            <Container className="p-0 overflow-hidden gradient-container" fluid>
                <FaqBanner data={homeData.dropdownFaq} />
            </Container>
        </Container>
    );
}
