import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { useBreakPoint } from "hooks/useBreakPoint";
import { ButtonComponent as Button } from "@components/Button";
import { parseText } from "utils/parse";
import { get, size } from "lodash";
import { ListGroup } from "react-bootstrap";
import { marked } from "marked";
import { Fragment } from "react";

export const CardsWithTopImage = ({ title, content, imgSrc, cardClass, simpleCardClass, cardBodyClass, showHr = false }) => {
    return (
        <Col>
            {imgSrc && (
                <Card className={`p-3 my-3 ${cardClass}`}>
                    <Card.Img src={imgSrc} alt="Card with Top Image"/>
                </Card>
            )}
            
            {showHr && <hr className="triple-cards-hr" />}

            <SimpleCard cardBodyClass={cardBodyClass} cardClass={simpleCardClass} title={title} content={content} />
        </Col>
    );
};

export const SimpleCard = ({
    title,
    content,
    cardBodyClass,
    cardClass,
    titleClass,
    cardTextTitleClass,
    children,
    quote,
}) => {
    const { smallScreen } = useBreakPoint();
    const smallScreenClass = smallScreen ? "w-100 lh-sm" : "w-75 lh-md";
let arrContent = [];
let contentIsNotArray = true;
if (content && content.includes("\n") && content.constructor === String) {
    contentIsNotArray = false;
    let innerArray = content.split(/\r?\n/);
    let innerObject = {};
    for (let i=0; i<innerArray.length; i++){
        innerObject = {
            content: innerArray[i]
        }
        arrContent.push(innerObject);
    }
}
    return (
        <Card className={`d-flex justify-content-start align-items-center ${cardClass}`}>
            <Card.Body
                className={`d-flex flex-column text-center py-4 ${smallScreenClass} justify-content-start ${cardBodyClass}`}>
                {quote && quote}
                {title && <h2 className={titleClass}>{title}</h2>}
                
                {content && contentIsNotArray && <Card.Text className={cardTextTitleClass}>{content}</Card.Text>}
                {content && !contentIsNotArray && 
                <Card.Text style={{"text-align": "left"}}>
                    {
                        arrContent.map(item => {
                            let contentOfArray = item.content;
                            return(parseText(contentOfArray+"<br>"))
                        })
                    }
                </Card.Text>}

                {children}
            </Card.Body>
        </Card>
    );
};

export const UlListCard = ({
    title,
    content,
    cardBodyClass,
    cardClass,
    titleClass,
    children,
    quote,
}) => {

    const getMarkdownText = (item) => {
        var rawMarkup = marked.parse(item);
        return { __html: rawMarkup };
    }

    return (
        <Card className={`d-flex justify-content-start align-items-center ${cardClass}`}>
            <Card.Body
                className={`d-flex flex-column py-5 lh-md w-100 justify-content-start ${cardBodyClass}`}>
                {quote && quote}
                {title && <h4 className={titleClass}>{title}</h4>}
                {Array.isArray(content) && 
                        content.map(item => size(item) > 0 ? <p dangerouslySetInnerHTML={getMarkdownText(item)}></p> : null)
                }
                {children}
            </Card.Body>
        </Card>
    );
}

export const RowListCard = ({
    title,
    content,
    cardBodyClass,
    cardClass,
    titleClass,
    children,
    quote,
}) => {
    return (
        <Card style={{ height: '14rem' }} className={`d-flex justify-content-start align-items-center ${cardClass}`}>
            <Card.Body 
                className={`d-flex flex-column py-5 lh-md w-100 justify-content-start ${cardBodyClass}`}>
                {quote && quote}
                {title && <h2 className={titleClass}>{title}</h2>}
                {Array.isArray(content) && 
                    <ul className="row-list-card">
                        {content.map(item => <li>{item}<hr/></li>)}
                    </ul> 
                }
                {children}
            </Card.Body>
        </Card>
    );
}

export const BulletedCard = ({
    title,
    content,
    cardBodyClass,
    cardClass,
    titleClass,
    children,
    quote,
}) => {

    const getMarkdownText = (item) => {
        var rawMarkup = marked.parse(item);
        return { __html: rawMarkup };
    }
    
    return (
        <Card className={`d-flex justify-content-start align-items-center bulleted-list ${cardClass}`}>
            <Card.Body
                className={`d-flex flex-column py-5 lh-md w-100 justify-content-start ${cardBodyClass}`}>
                {quote && quote}
                {title && <h2 className={titleClass}>{title}</h2>}
                {Array.isArray(content) && 
                        content.map(item => size(item) > 0 ? <p dangerouslySetInnerHTML={getMarkdownText(item)}></p> : null)
                }
                {children}
            </Card.Body>
        </Card>
    );
}

export const SimpleCardWithButton = ({ data, rowClass, cardClass, cardBodyClass, titleClass, cardTextTitleClass, waveStyle = {maxHeight: "100%"} }) => {
    const { header, bodyText, cta } = data;
    const label = cta[0].fields.button;
    const url = cta[0].fields.cta_url;

    return (
        <>
            <div className="nclex-wave-2" style={waveStyle}></div>
            <Row className={`m-3 ${rowClass}`}>
                <Card
                    className={`simple-card-with-button d-flex justify-content-start align-items-center ${cardClass}`}>
                    <Card.Body
                        className={`d-flex flex-column text-center py-5 lh-md w-75 justify-content-start ${cardBodyClass}`}>
                        {header && <h2 className={titleClass}>{header}</h2>}
                        {bodyText && <Card.Text className={cardTextTitleClass}>{bodyText}</Card.Text>}
                        <Button label={label} url={url} />
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

const PriceComponent = ({ priceClass, price, onMouseEnterUsa, onMouseEnterCanada, onMouseLeave }) => {
    const { break768 } = useBreakPoint();

    if (!price) {
        return null;
    }
    if (!break768) {
        return (
            <div className="d-flex my-3">
                {price && price.canada &&
                    <div onMouseEnter={onMouseEnterCanada} onMouseLeave={onMouseLeave}>
                        <Image className="d-block price-flag canada" src={require("../../assets/mock/canada_flag.png")} alt="Canada Flag"/>
                    </div>
                }

                <div onMouseEnter={onMouseEnterUsa} onMouseLeave={onMouseLeave}>
                    <Image className="d-block price-flag usa" src={require("../../assets/mock/usa_flag.png")} alt="USA Flag" />
                </div>
            </div>
        );
    }

    if (price && price.canada && price.canada.startsWith('Looking')){
        return (
            <div>
                <Card.Text className={`${priceClass}`}>Looking for institutional prices?</Card.Text>
                <Card.Text className={`${priceClass}`}>Contact a representative.</Card.Text>
            </div>
        )
    }

    return (
        <div>
            {price && price.canada &&
                <div className="d-flex, flex-column">
                    <h4 className="mb-0">CANADA:</h4>
                    <ListGroup.Item className={`${priceClass}`}>{price.canada}</ListGroup.Item>
                </div>
            }   
            {Array.isArray(price.usa) ? (
                <div className="d-flex, flex-column">
                    <h4 className="mb-0">USA:</h4>
                    {price.usa.map((item) => (
                        <ListGroup.Item className={`${priceClass}`}>{item}</ListGroup.Item>
                    ))}
                </div>
            ) : (
                <Card.Text className={`${priceClass}`}>{price.usa}</Card.Text>
            )}
        </div>
    );
};

export const CardWithButton = ({
    title,
    subtitle,
    content,
    label,
    url,
    cardClass,
    cardBodyClass,
    titleClass,
    subtitleClass,
    cardTextClass,
    buttonClass,
    price,
    priceClass,
}) => {
    const [hover, setHover] = useState("");
    const [color, setColor] = useState('orange');

    const showContent = (type = "usa") => {
        if (type === "usa") {
            return price.usa.map((item) => parseText(`${item}\r\n`));
        }
        if (type === "canada") {
            return price.canada;
        }
        return content;
    };

    let newTitle = <h2 className={titleClass}>{title}</h2>;
    if (Array.isArray(title)) {
        newTitle = title.map(word => {
            return(<h2 className={titleClass} key={word}>{word}</h2>)
        })
    }

    let newSubtitle = subtitle;

    if (Array.isArray(subtitle)) {
        newSubtitle = subtitle.map((word, index) => {
            let isLastItem = index === subtitle.length - 1
            if (!isLastItem) {
                return(<Fragment key={word}>{' '+word}</Fragment>);
            } else {
                return(<div className={subtitleClass} key={word}>{word}</div>);
            }
        })
        newSubtitle = <span className={subtitleClass}>{newSubtitle}</span>
    }
    
    return (
        <Card className={`card-with-button ${cardClass}`} onMouseEnter={()=>setColor('green')} onMouseLeave={()=>setColor('orange')}>
            <Card.Body
                className={`d-flex flex-column align-items-center text-center ${cardBodyClass}`}>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    {newTitle}
                    {newSubtitle}
                </div>
                <hr className="card-hr w-100" />
                <Card.Text className={cardTextClass}>{showContent(hover)}</Card.Text>
                <PriceComponent
                    onMouseLeave={() => setHover("")}
                    onMouseEnterUsa={() => setHover("usa")}
                    onMouseEnterCanada={() => setHover("canada")}
                    priceClass={priceClass}
                    price={price}
                />

                <Button containerClass={buttonClass} label={label} url={url} color={color} colorOverride={color === 'green'}/>
            </Card.Body>
        </Card>
    );
};

export const CardsWithOverlayImage = ({
    title,
    imgSrc,
    label,
    url,
    cardClass,
    type,
    bodyText,
    active,
    classes = {},
}) => {
    return (
        <Card className={cardClass}>
            <Card.Body className={`position-relative ${classes.cardBody}`}>
                {type === "asymetric" || type === "carousel" ? (
                    <div className={`position-absolute ${classes.contentContainer}`}>
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <h2
                                className={`${
                                    type !== "carousel" || (type !== "asymetric" && "mb-4")
                                } text-white font-weight-bold double-card-h2 ${classes.title}`}>
                                {title}
                            </h2>
                            {type === "carousel" ||
                                (type === "asymetric" && (
                                    <Card.Text className={`w-60 text-white ${classes.bodyText}`}>
                                        {bodyText}
                                    </Card.Text>
                                ))}
                                <Button
                                    containerClass={`${type !== "carousel" || (type !== "asymetric" && "mt-6")}`}
                                    label={label}
                                    url={url}
                                />
                        </div>
                    </div>
                ) : (
                        <div className={`position-absolute ${classes.contentContainer}`}>
                            <div className="d-flex flex-column justify-content-center align-items-center ">
                                <h2
                                    className={`${
                                        type !== "carousel" || (type !== "asymetric" && "mb-4")
                                    } text-white font-weight-bold double-card-h2 ${classes.title}`}>
                                    {title}
                                </h2>
                                {type === "carousel" ||
                                    (type === "asymetric" && (
                                        <Card.Text className={`w-60 text-white ${classes.bodyText}`}>{bodyText}</Card.Text>
                                    ))}
                                <Button
                                    containerClass={`${type !== "carousel" || (type !== "asymetric" && "mt-6")}`}
                                    label={label}
                                    url={url}
                                />
                            </div>
                        </div>
                    )
                }

                <Card.Img
                    style={{borderRadius: "5px"}}
                    className={"double-card-image"}
                    variant="top"
                    src={imgSrc}
                    alt="Double Card Image"
                />
                {type === "carousel"  && <div className={`card-overlay ${active ? "card-overlay-active" : "card-overlay-inactive"}`}></div>}
                {(type === "asymetric" || type === "ambassadors") && <div className={`card-overlay-asymetric  ${active ? "card-overlay-asymetric-active" : "card-overlay-asymetric-inactive"}`}></div>}
            </Card.Body>
        </Card>
    );
};

export const BigCardWithOverlay = ({data, cardClass, classes}) => {
    const bodyText = get(data, "bodyText", "");
    const cardHeader = get(data, "header", "");
    const imageUrl = get(data, "backgroundImage.fields.file.url", "/");
    const buttonUrl = get(data, "cta[0].fields.cta_url", null);
    const buttonLabel = get(data, "cta[0].fields.button", null);
    const hasButton = get(data, "cta", false);

    const { isMobile, smallScreen } = useBreakPoint();

    return (
        <div className={`w-75 mx-auto border-0 shadow-none show-content ${smallScreen ? "w-100" : ""} ${cardClass}`}>
            <Card.Body className={`position-relative justify-content-center big-card ${classes?.cardBody}`}>
                <div className={`d-flex position-absolute align-items-center justify-content-center ${classes?.contentContainer}`}>
                    <div className={`d-flex flex-column justify-content-center align-items-center w-80`}>
                        <h1
                            className={`text-center text-white font-weight-bold  ${smallScreen ? "mb-0" : "mb-4"}  ${isMobile ? "big-card-title" : ""} ${classes?.title}`}>
                            {cardHeader}
                        </h1>
                        <Card.Text className={` text-white text-center w-100  ${isMobile ? "mb-0 big-card-subtitle" : ""} ${classes?.bodyText}`}>{bodyText}</Card.Text>
                        {hasButton && 
                            <Button
                                containerClass={""}
                                label={buttonLabel}
                                url={buttonUrl}
                            />
                        }
                    </div>
                </div>

                <Card.Img
                    className={"double-card-image"}
                    variant="top"
                    src={imageUrl}
                    alt="Double Card Image"
                />
            </Card.Body>
        </div>
    )
};

export const BlogCard = ({
    title,
    content,
    cardBodyClass,
    cardClass,
    titleClass,
    cardTextTitleClass,
    children,
    quote,
}) => {
    const { smallScreen } = useBreakPoint();
    const smallScreenClass = smallScreen ? "w-100 lh-sm" : "w-75 lh-md";

    return (
        <Card className={`d-flex ${cardClass}`}>
            <Card.Body
                className={`d-flex flex-column py-4 ${smallScreenClass} ${cardBodyClass}`}>
                {quote && quote}
                {title && <h2 className={titleClass}>{title}</h2>}
                {content && <Card.Text className={cardTextTitleClass}>{content}</Card.Text>}
                {children}
            </Card.Body>
        </Card>
    );
};