import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import {
    DoubleCards,
    HorizontalBanner,
    TripleCards,
    BigBanner,
    PageHero,
    SeoTags,
} from "@components/index";
import { useLive } from "hooks/nclexreview/useLive";

//const parse = require("html-react-parser");


export function Live() {
    // CommonJS
    const data = useLive();
    const seoData = useSEO(contentFulIds.REACT_APP_NCLEX_LIVE_PAGE_SEO_ID);
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);

    if (!data || !seoData) {
        return null;
    }

    return (
        <Container className="p-0 overflow-hidden page-live" fluid>
            <SeoTags data={seoData} />
            <PageHero data={data.hero} />
            <TripleCards waveType="nclex" data={data.tripleCardsTextOutside} extraWaveClass="wave-fixed"/>
            {data.banner ? (
                <HorizontalBanner
                    buttonColClass="nclex-horizontal-button-col d-flex my-3"
                    rowClass="nclex-horizontal-banner-row"
                    data={data.banner}
                />
                ) : (
                    <span/>
            )}
            
            <BigBanner
                imgContainerClass="nclex-bigbanner-img-container"
                reverse
                data={data.heroBadge}
                extradata={data.rowPillarLeft}
            />
            <DoubleCards type="carousel" extraWaveClass="nclex-wave-generic-2 wave-fixed" waveType="nclex" rowClass={"nclex-double-card-row"} data={data.tripleCards} />
            <div className="top-spacer"/>
            <DoubleCards type="asymetric"  data={data.tripleCardsTextAsymmetric} />
        </Container>
    );
}
