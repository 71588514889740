import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { useResourcesFaq } from "@hooks/resources/faq/useResourcesFaq";
import { useSEO } from "@hooks/useSEO";
import { contentFulIds } from "@services/contentfulIds";
import {
    LandingHero,
    FaqBanner,
    SeoTags,
} from "@components/index";
import { FilterCarousel } from "components/FilterCarousel";

export function ResourcesFaq() {
    const [selectedCategory, setSelectedCategory] = useState('');

    const data = useResourcesFaq();
    const seoData = useSEO(contentFulIds.REACT_APP_RESOURCES_FAQ_PAGE_SEO_ID);

    useEffect(() => {
        setSelectedCategory('GENERAL');
        pageView(window.location.href);
    }, []);
    

    if (!data || !seoData) {
        return null;
    }
    

    let dataDropDownFaq = data.dropdownFaq

    let filteredFaqByCategory = {
        dropdownFaqName: dataDropDownFaq.dropdownFaqName,
        faqComponent: []
    }

        if(selectedCategory !== undefined && selectedCategory !== ""){
            for (let i=0; i<data.dropdownFaq.faqComponent.length; i++){
                
                if(data.dropdownFaq['faqComponent'][i] !== undefined && data.dropdownFaq['faqComponent'][i]['fields']['faqCategory'] && data.dropdownFaq['faqComponent'][i]['fields']['faqCategory']['fields']['faqCategory'] === selectedCategory){
                    filteredFaqByCategory['faqComponent'].push(dataDropDownFaq['faqComponent'][i])
                }
            }
            dataDropDownFaq = filteredFaqByCategory
        }

    return (
        <Container className="p-0 overflow-hidden page-faq" fluid>
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} shapeColor='green' />
            <div className="nclex-wave-1 landing-hero-wave faq-wave"></div>
            <FilterCarousel
                selectedCategory={selectedCategory}
                data={data.faqCategorySelector}
                setSelectedCategory={setSelectedCategory}
             />
            <FaqBanner data={dataDropDownFaq} waveType={"faq"} />
        </Container>
    );
}
