import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import pageView from "@components/GTM";
import { LandingHero } from "@components";
import { useContact } from "@hooks/useContact";
import { useSEO } from "@hooks/useSEO";
import { SeoTags } from "@components/index";
import { contentFulIds } from "@services/contentfulIds";
import { ContactForm, ContactOverride } from "./styles";
import Select, { components } from 'react-select'
import SelectArrow from "@assets/contactImages/SelectArrow.png";
import { isNil, size } from 'lodash';
import { ButtonComponent as Button } from "@components/Button";
import clockIcon from "@assets/contactImages/clockIcon.png";
import localIcon from "@assets/contactImages/localIcon.png";
import phoneIcon from "@assets/contactImages/phoneIcon.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Contact() {
    const data = useContact();
    const seoData = useSEO(contentFulIds.REACT_APP_CONTACTFORM_PAGE_SEO_ID);

    let [contactInfo, setContactInfo] = useState({});
    let [errors, setErrors] = useState([]);
    //const [selectedValue, setSelectedValue] = useState("AL");
    
    useEffect(() => {
        pageView(window.location.href);
    }, []);
    
    if (!data || !seoData) {
        return null;
    }

    const showToastErrorMessage = () => {
        toast.error('An unexpected error has occurred. Please try again in a few minutes.', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const showToastSuccessMessage = () => {
        toast.success('Thanks for contacting us. We have received your inquiry and will get back with you shortly.', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const showToastErrorFieldsMessage = () => {
        toast.error('Please fill in all the form fields.', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const reasonOptions = [
        { value: "educator", label: "I'm an Educator and would like more information"},
        { value: "other", label: "I need to discuss something else"},
        { value: "sales", label: "I need more information about your course"},
        { value: "support", label: "I'd like to schedule an info session or review at my school"},
        { value: "work-for-us", label: "I'd like to work for Hurst Review"}
    ];

    const contactFields = [
        { name: "contactFirstName", description: "First Name", type: "text", size: "half", tabindex: 11},
        { name: "contactLastName", description: "Last Name", type: "text", size: "half", tabindex: 12},
        { name: "contactEmail", description: "Email", type: "email", tabindex: 13},
        { name: "contactSubject", description: "Subject", type: "text", tabindex: 14},
        { name: "contactMessage", description: "Message", type: "text", tabindex: 15},
        { name: "contactPhone", description: "Phone", type: "text", tabindex: 16},
        { name: "contactReason", description: "Reason for getting in touch", type: "select", tabindex: 17},
        { name: "contactStateProvince", description: "Location", type: "select", tabindex: 18}
    ];

    const onChangeContactForm = event => {
        let newContactInfo = { ...contactInfo, [event.target.name]: event.target.value};

        setErrors([]);

        setContactInfo(newContactInfo);

    }

    const checkRequiredContactFields = () => {
        let errors = [];

        contactFields.forEach(field => {
            if(isNil(contactInfo[field.name]) || size(contactInfo[field.name]) === 0) {
                errors.push(`${field.name}`)
            }
        })
        setErrors(errors);

        return size(errors) > 0;
    }

    const onSubmitHandler = async (event) => {
        //const onSubmitHandler = async (event) => {
        //const onSubmitHandler = event => {
        
        let contactName = contactInfo.contactFirstName + " " + contactInfo.contactLastName;
        event.preventDefault()
        
        let hasErrors = checkRequiredContactFields();
        
        if (hasErrors){
            showToastErrorFieldsMessage(); 
            return false;
        }
        
        let res = await fetch("https://api2.hurstreview.com/contact", {
                method: "POST",
                //mode: 'no-cors',
                headers: new Headers({
                    'x-api-key': 'iRUvVE1dSw9zailWuMFMe7xdadPL2JBd1Mzoluxt',
                    'Content-Type': 'application/json',
                    Accept: '*/*',
                    'Allow-Credentials': true
                    
                }),
                //headers: {},
                body: JSON.stringify({
                    "contactEmail": contactInfo.contactEmail, 
                    "contactMessage": contactInfo.contactMessage, 
                    "contactName": contactName, 
                    "contactPhone": contactInfo.contactPhone, 
                    "contactReason": contactInfo.contactReason,
                    "contactStateProvince": contactInfo.contactStateProvince,
                    "contactSubject": contactInfo.contactSubject 
                }),
        });
        if(res.status === 200) {
        //    setMessage("Thanks for contacting us. We have received your inquiry and will get back with you shortly");
            //console.log("Thanks for contacting us. We have received your inquiry and will get back with you shortly");
            showToastSuccessMessage();
        } else {
            showToastErrorMessage();
          
        }        
    }

    const onChangeSelectField = (name, value) => {
        let newContactInfo = { ...contactInfo, [name]: value};

        setErrors([]);

        setContactInfo(newContactInfo);
    }

    const stateOptions = [
        { 
            label: "US States",
            options: [
                { value: "AL", label: "Alabama" },
                { value: "AK", label: "Alaska" },
                { value: "AZ", label: "Arizona" },
                { value: "AR", label: "Arkansas" },
                { value: "CA", label: "California" },
                { value: "CO", label: "Colorado" },
                { value: "CT", label: "Connecticut" },
                { value: "DE", label: "Delaware" },
                { value: "DC", label: "District Of Columbia" },
                { value: "FL", label: "Florida" },
                { value: "GA", label: "Georgia" },
                { value: "HI", label: "Hawaii" },
                { value: "ID", label: "Idaho" },
                { value: "IL", label: "Illinois" },
                { value: "IN", label: "Indiana" },
                { value: "IA", label: "Iowa" },
                { value: "KS", label: "Kansas" },
                { value: "KY", label: "Kentucky" },
                { value: "LA", label: "Louisiana" },
                { value: "ME", label: "Maine" },
                { value: "MD", label: "Maryland" },
                { value: "MA", label: "Massachusetts" },
                { value: "MI", label: "Michigan" },
                { value: "MN", label: "Minnesota" },
                { value: "MS", label: "Mississippi" },
                { value: "MO", label: "Missouri" },
                { value: "MT", label: "Montana" },
                { value: "NE", label: "Nebraska" },
                { value: "NV", label: "Nevada" },
                { value: "NH", label: "New Hampshire" },
                { value: "NJ", label: "New Jersey" },
                { value: "NM", label: "New Mexico" },
                { value: "NY", label: "New York" },
                { value: "NC", label: "North Carolina" },
                { value: "ND", label: "North Dakota" },
                { value: "OH", label: "Ohio" },
                { value: "OK", label: "Oklahoma" },
                { value: "OR", label: "Oregon" },
                { value: "PA", label: "Pennsylvania" },
                { value: "RI", label: "Rhode Island" },
                { value: "SC", label: "South Carolina" },
                { value: "SD", label: "South Dakota" },
                { value: "TN", label: "Tennessee" },
                { value: "TX", label: "Texas" },
                { value: "UT", label: "Utah" },
                { value: "VT", label: "Vermont" },
                { value: "VA", label: "Virginia" },
                { value: "WA", label: "Washington" },
                { value: "WV", label: "West Virginia" },
                { value: "WI", label: "Wisconsin" },
                { value: "WY", label: "Wyoming" }
            ]
        },
        {
            label: "Canadian Provinces",
            options: [
                { value: "AB", label: "Alberta" },
                { value: "BC", label: "British Columbia" },
                { value: "MB", label: "Manitoba" },
                { value: "NB", label: "New Brunswick" },
                { value: "NL", label: "Newfoundland and Labrador" },
                { value: "NT", label: "Northwest Territories" },
                { value: "NS", label: "Nova Scotia" },
                { value: "NU", label: "Nunavut" },
                { value: "ON", label: "Ontario" },
                { value: "PE", label: "Prince Edward Island" },
                { value: "QC", label: "Quebec" },
                { value: "SK", label: "Saskatchewan" },
                { value: "YT", label: "Yukon" }
            ]
        }
    ];
    
    const customStyles = {
        
        control: (base, state) => ({
            ...base,
            background: "#FFFFFF",
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "grey" : "darkgrey",
            borderRadius: 20,
            minHeight: '65px',
            height: '65px',
            paddingRight: '15px',
            paddingLeft: '25px',
            paddingTop: '3px',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
              // Overwrittes the different states of border
              borderColor: state.isFocused ? "grey" : "darkgrey"
            }
          }),
          dropdownIndicator: base => ({
              ...base,
              color: "grey"
          }),
          option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#EA600E" : "white",
              '&:hover': { backgroundColor:  'orange'  }
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 })
          
      };
    
    const DropdownIndicator = props => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <img src={SelectArrow} alt="Select Arrow"></img>
            </components.DropdownIndicator>
          )
        );
      };
    
    return (
        <Container className="p-0 overflow-hidden" fluid>
            <ToastContainer />
            <SeoTags data={seoData} />
            <LandingHero data={data.hero} shapeColor='orange'/>
            <div className="nclex-wave-1 contact-wave landing-hero-wave"></div>
                <ContactOverride>
                    <div id="contacts-container">
                    <div className="container-left">
                    <div className="top-spacer"/>
                        <div id="address-box">
                            <div id="address-box-image">
                                <img src={localIcon} alt="Local Icon"></img>
                            </div>
                            <div id="address-box-text">
                                127 South Railroad Ave<br/>Brookhaven, MS<br/>39601
                            </div>
                        </div>
                        <div id="phone-box">
                            <div id="phone-box-image">
                                <img src={phoneIcon} alt="Phone Icon"></img>
                            </div>
                            <div id="phone-box-text">
                                601.833.1961
                            </div>
                        </div>
                        <div id="schedule-box">
                            <div id="schedule-box-image">
                                <img src={clockIcon} alt="Clock Icon"></img>
                            </div>
                            <div id="schedule-box-text">
                                Monday - Friday<br/>8 AM - 5 PM US CST
                            </div>
                        </div>
                    </div>
                    <div className="container-right">
                    <div className="top-spacer"/>
                    <section>
                    <ContactForm onSubmit={onSubmitHandler}>
                     {contactFields.map(field => {
                                        
                                        const invalid = errors.includes(field.name);
                                        
                                        if (field.type === "select") return (
                                            <div key={field.name} tabindex={field.tabindex} className="contactInputField">
                                                <label>
                                                    {field.description}*
                                                </label>
                                                <Select
                                                    placeholder={field.name === "contactReason" ? 'The reason you are getting in touch' : ''}
                                                    styles={customStyles}
                                                    options={field.name === "contactReason" ? reasonOptions : stateOptions}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                        DropdownIndicator
                                                    }}
                                                    onChange={event => onChangeSelectField(field.name, event.value)}
                                                />
                                                <br />
                                            </div>     
                                        );

                                        return(
                                            <>
                                            <div key={field.name} className="contactInputField">
                                                <label>
                                                    {field.description}*
                                                </label>
                                                {field.name !== 'contactMessage' ? 
                                                        <input
                                                            className="standardInput"
                                                            style={
                                                                invalid ? { borderColor: 'red' } : {}
                                                            }
                                                            id={field.name}
                                                            name={field.name}
                                                            type={field.type}
                                                            value={contactInfo[field.name]}
                                                            onChange={onChangeContactForm}
                                                        />
                                                    :
                                                        <textarea
                                                            style={
                                                                invalid ? { borderColor: 'red' } : {}
                                                            }
                                                            id={field.name}
                                                            name={field.name}
                                                            type={field.type}
                                                            value={contactInfo[field.name]}
                                                            onChange={onChangeContactForm}
                                                            cols="40" rows="5"
                                                        />
                                                }
                                            </div>
                                            </>
                                        )
                                    })}
                                    <div className="top-spacer"/>
                                    <div tabindex="0" onKeyDown={onSubmitHandler} onClick={onSubmitHandler} className="center-form-button">
                                        <Button 
                                            containerClass="horizontal-filter-button" 
                                            key='contact'
                                            label='SUBMIT'
                                            hasOnclick='block'
                                            hasUrl='block'
                                        />
                                    </div>
                                    <div className="top-spacer"/>
                    </ContactForm>
                    </section>
                                
                    
                    </div>
                    </div>   
                </ContactOverride>
        </Container>
    );
}
