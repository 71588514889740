import { HeaderComponent } from "@components/Header";
import { Outlet } from "react-router-dom";
import { FooterComponent } from "@components/Footer";

export function DefaultLayout() {
    return (
        <>
            <HeaderComponent />
            <Outlet />
            <FooterComponent />
        </>
    )
}